import request from "server";

/**
 * 获取筛选条件
 */
const apiGetNewEmployeeScreenCondition = (data: object = {}) => {
    return request({
        url: `/recruitment/newEmployee/getScreeningCondition`,
        method: 'get',
        data
    })
}
/**
 * 获取接人名单列表
 */
const apiGetNewEmployeeReceptionFindPage = (data: object = {}) => {
    return request({
        url: `/recruitment/newEmployee/receptionFindPage`,
        method: 'post',
        data
    })
}
/**
 * 获取录取名单列表
 */
const apiGetNewEmployeeRecruitComeFindPage = (data: object = {}) => {
    return request({
        url: `/recruitment/newEmployee/recruitFindPage`,
        method: 'post',
        data
    })
}
/**
 * 获取报道名单列表
 */
const apiGetNewEmployeeComeFindPage = (data: object = {}) => {
    return request({
        url: `/recruitment/newEmployee/comeFindPage`,
        method: 'post',
        data
    })
}
/**
 * 查询客服订单分页
 */
const apiKfSearchs = (data: object = {}) => {
    return request({
        url: `/customer/order/kfSearchs`,
        method: 'post',
        data
    })
}
/**
 * 批量转在职
 */
const apiUpdateBatchInOfficeEmployee = (data: object = {}) => {
    return request({
        url: `/recruitment/newEmployee/updateBatchInOfficeEmployee`,
        method: 'post',
        data
    })
}
// 获取计费日期
const updateBatchChargingDate = (data: object = {}) => {
    return request({
        url: `/recruitment/newEmployee/updateBatchChargingDate`,
        method: 'post',
        data
    })
}
/**
 * 根据权限返回客户 
 */
const apiFindCustomersByUserToken = (data: object = {}) => {
    return request({
        url: `/customer/customerService/findCustomersByUserToken`,
        method: 'get',
        data
    })
}
/**
 * 根据客户返回部门 
 */
const apiCustomersBackDepartment = (customerId: string) => {
    return request({
        url: `/customer/customerService/getCustomerByCustomerServiceUserIds?customerId=${customerId}`,
        method: 'get',
        data: {}
    })
}

// 导入在职是否有权限
const getImportOnjobFlag = (departmentId: string) => {
    return request({
        url: `/customer/customerService/getImportOnjobFlag?departmentId=${departmentId}`,
        method: 'get',
    })
}
/**
 * 根据身份证名字查询企业员工
 */
const apiGetMemberByCustomerId = (data: { customerId: string, searchTerms: string }) => {
    return request({
        url: `/recruitment/newEmployee/getMemberByCustomerId`,
        method: 'post',
        data
    })
}
/**
 * 新员工导出
 */
const apiExportAllData = (data: object = {}, url: string) => {
    return request({
        url: url,
        method: 'post',
        data
    })
}
/**
 * 在职员工导出
 */
const apiExportEmployee = (customerId: string) => {
    return request({
        url: `/recruitment/newEmployee/exportEmployee/${customerId}`,
        method: 'get',
        data: {}
    })
}
const apiNewEmployee = {
    apiGetNewEmployeeScreenCondition,
    apiGetNewEmployeeReceptionFindPage,
    apiGetNewEmployeeRecruitComeFindPage,
    apiGetNewEmployeeComeFindPage,
    apiKfSearchs,
    apiUpdateBatchInOfficeEmployee,
    apiFindCustomersByUserToken,
    apiCustomersBackDepartment,
    getImportOnjobFlag,
    apiGetMemberByCustomerId,
    apiExportAllData,
    apiExportEmployee,
    updateBatchChargingDate,
}

export default apiNewEmployee
