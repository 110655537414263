import request from "server";

/**
 * 运营管理
 * @param data 
 * @returns 
 */

export const getBlackList = (data: any) => {
    return request({
        url: '/member/memberBlacklist/queryPageCondition',
        method: 'post',
        data
    })
}
// 新增黑名单
export const addBlackList = (data: any) => {
    return request({
        url: '/member/memberBlacklist',
        method: 'post',
        data
    })
}

// 查询非常规用户详情
export const getDetail = (blacklistId: string) => {
    return request({
        url: `/member/memberBlacklist/getById/${blacklistId}`,
        method: 'get'
    })
}
// 移除非常规用户
export const deleteUser = (data: any) => {
    return request({
        url: `/member/memberBlacklist/nonRegularUsers/remove`,
        method: 'post',
        data
    })
}

// 投诉列表
export const getComplaintList = (data: any) => {
    return request({
        url: `/system/complaint/web/list`,
        method: 'post',
        data
    })
}

// 新增投诉
export const addComplaint = (data: any) => {
    return request({
        url: `/system/complaint/web`,
        method: 'post',
        data
    })
}
// 获取企业
export const getAllCustomerList = (data: object = {}) => {
    return request({
        url: `/customer/customerInfo/findAll`,
        method: 'get',
        data
    })
}

// 企业下部门
export const listDepartment = (data: any) => {
    return request({
        url: `/customer/department/listDepartment`,
        method: 'post',
        data
    })
}
// 企业下部门
export const getAlltDepartmentList = (customerId: string) => {
    return request({
        url: `/customer/department/findAll/${customerId}`,
        method: 'get',
        data: {}
    })
}

// 获取客服 招聘员
export const getDataList = (roleKey: string) => {
    return request({
        url: `system/role/authUser/selectUserByRoleKey/${roleKey}`,
        method: 'get',
    })
}
// 获取投诉部门
export const getComplaintDeptList = () => {
    return request({
        url: `/system/dept/getMainDeptList`,
        method: 'get',
    })
}

// 投诉详情
export const getComplaintDetail = (id: string) => {
    return request({
        url: `/system/complaint/web/${id}`,
        method: 'get',
    })
}

// 投诉判定
export const complaintJudgeData = (data: any) => {
    return request({
        url: `/system/complaint/web/judge`,
        method: 'post',
        data
    })
}
// 投诉处理
export const complaintHandle = (data: any) => {
    return request({
        url: `/system/complaint/web/handle`,
        method: 'post',
        data
    })
}
// 投诉回访
export const complaintReturnVisit = (data: any) => {
    return request({
        url: `/system/complaint/web/returnVisit`,
        method: 'post',
        data
    })
}

// 投诉结案
export const complaintClose = (data: any) => {
    return request({
        url: `/system/complaint/web/close`,
        method: 'post',
        data
    })
}
// 回访后修改问题类型
export const afterReturnVisit = (data: any) => {
    return request({
        url: `/system/complaint/web/afterReturnVisit`,
        method: 'post',
        data
    })
}
/**
 * 
 * @param data 检举
 * @returns 
 */
// 检举列表
export const getProsecutionListForWeb = (data: any) => {
    return request({
        url: `/system/prosecution/getProsecutionListForWeb`,
        method: 'post',
        data
    })
}
// 检举详情
export const getImpeachDetail = (data: any) => {
    return request({
        url: `/system/prosecution/getProsecutionDetailByProsecutionIdForWeb`,
        method: 'post',
        data
    })
}
// 检举-获取组织架构人员列表
export const getDeptUsers = () => {
    return request({
        url: `/system/prosecution/getDeptUsers`,
        method: 'get',
    })
}
// 检举-保存审批结果
export const insertApprove = (data: any) => {
    return request({
        url: `/system/prosecution/insertApprove`,
        method: 'post',
        data
    })
}

// 情况说明
export const insertContent = (data: any) => {
    return request({
        url: `/system/complaint/insertContent`,
        method: 'post',
        data
    })
}
// 线上报名列表
export const signUpList = (data: any) => {
    return request({
        url: `/system/resourceAllocation/list`,
        method: 'post',
        data
    })
}
// 资源分配
export const resourceAllocation = (data: any) => {
    return request({
        url: `/system/resourceAllocation/allocation`,
        method: 'post',
        data
    })
}

// 报道调查列表
export const getSurveyReportsList = (data: any) => {
    return request({
        url: `/recruitment/jobseekersurvey/getPageList`,
        method: 'post',
        data
    })
}
// 报道调查详情
export const getSurverDetail = (surveyId: string) => {
    return request({
        url: `/recruitment/jobseekersurvey/getSurveyDetails/${surveyId}`,
        method: 'get',
    })
}

// 运营管理-标签管理
export const getLabelManegeList = (data: any) => {
    return request({
        url: `/recruitment/tag/listTagPage`,
        method: 'post',
        data
    })
}
// 运营管理-标签管理-查看标签信息
export const getSeeLabelInfo = (tagValueId: number) => {
    return request({
        url: `/recruitment/tag/getTagInfo?tagValueId=${tagValueId}`,
        method: 'get',
        data: {}
    })
}
// 运营管理-标签管理-新增标签类型
export const addLabelType = (data: { tagTypeName: string }) => {
    return request({
        url: `/recruitment/tag/saveTagType`,
        method: 'post',
        data
    })
}
// 标签类型列表
export const getLabelTypeList = () => {
    return request({
        url: `/recruitment/tag/listTagType`,
        method: 'get',
        data: {}
    })
}
// 标签名称列表
export const getLabelNameList = (tagTypeId: number) => {
    return request({
        url: `/recruitment/tag/listTag?tagTypeId=${tagTypeId}`,
        method: 'get',
        data: {}
    })
}
// 删除标签名称
export const delLabelName = (tagId: number) => {
    return request({
        url: `/recruitment/tag/removeTag?tagId=${tagId}`,
        method: 'get',
        data: {}
    })
}
// 新增标签名称
export const addLabelName = (data: { tagName: string, attribute: number, tagTypeId: number }) => {
    return request({
        url: `/recruitment/tag/saveTag`,
        method: 'post',
        data
    })
}
// 重命名标签名称
export const resetLabelName = (data: { tagName: string, tagId: number }) => {
    return request({
        url: `/recruitment/tag/updateTagName`,
        method: 'post',
        data
    })
}
// 标签值列表
export const getLabelValueList = (tagId: number) => {
    return request({
        url: `/recruitment/tag/listTagValue?tagId=${tagId}`,
        method: 'get',
        data: {}
    })
}
// 删除标签值
export const delLabelValue = (tagValueId: number) => {
    return request({
        url: `/recruitment/tag/removeTagValue?tagValueId=${tagValueId}`,
        method: 'get',
        data: {}
    })
}
// 新增标签名称
export const addLabelValue = (data: { tagValue: string, status: number, tagId: number }) => {
    return request({
        url: `/recruitment/tag/saveTagValue`,
        method: 'post',
        data
    })
}
// 重命名标签值
export const resetLabelValue = (data: { tagValue: string, tagValueId: number, remark?: string }) => {
    return request({
        url: `/recruitment/tag/updateTagValue`,
        method: 'post',
        data
    })
}

/**
 * 运营消息管理
 * @param data 
 * @returns 
 */

// 标签级联数据
export const tagsList = (level = 3) => {
    return request({
        url: `/recruitment/tag/listLevel3Linkage?level=${level}`,
        method: 'get',
    })
}
// 短信模板查询
export const queryMarketSmsTemplate = () => {
    return request({
        url: `/member/msgPaln/queryMarketSmsTemplate`,
        method: 'get',
    })
}

export const queryMarketSmsTemplateNew = (data: any) => {
    return request({
        url: `/member/msgPaln/queryMarketSmsTemplateForm`,
        method: 'post',
        data,
    })
}

/**
 * 自主报名 - 短信模板
 */
export const sendMobileSMS = (data: any) => {
    return request({
        url: `/recruitment/smsTemplate/sendMobileSMS`,
        method: 'post',
        data
    })
}
export const getSendRecordsList = (data: any = {}) => {
    return request({
        url: `/recruitment/smsTemplate/getSendRecordsList`,
        method: 'post',
        data
    });
}


// 运营消息列表
export const messageList = (data: any) => {
    return request({
        url: `/member/msgPaln/page`,
        method: "post",
        data,
    })
}
/**
 * 客服消息管理
 * @param data 
 * @returns 
 */
// 客服消息列表
export const serviceMsgList = (data: any) => {
    return request({
        url: `/member/serviceMsg/serviceMsgList`,
        method: "post",
        data,
    })
}
// 提醒未读
export const remindNotRead = (data: any) => {
    return request({
        url: `/member/serviceMsg/remindNotRead`,
        method: "post",
        data,
    })
}
// 客服消息详情
export const serviceMsgDetail = (data: any) => {
    return request({
        url: `/member/serviceMsg/serviceMsgDetail`,
        method: 'post',
        data
    })
}
// 新增编辑客服消息
export const saveServiceMsg = (data: any) => {
    return request({
        url: `/member/serviceMsg/saveAndUpdate`,
        method: 'post',
        data
    })
}
// 查看人员名单
export const serviceMsgPersonList = (data: any) => {
    return request({
        url: `/member/serviceMsg/serviceMsgPersonList`,
        method: 'post',
        data
    })
}

// 发送人员统计
export const sendCount = (data: any) => {
    return request({
        url: `/member/msgPaln/sendCount`,
        method: "post",
        data,
    })
}

/**
 * 渠道管理
 * @param data 
 * @returns 
 */

export const channelList = (data: any) => {
    return request({
        url: `/system/channel/page`,
        method: 'post',
        data
    })
}

// 添加修改渠道
export const updateChannel = (data: any) => {
    return request({
        url: `/system/channel/saveOrUpdate`,
        method: 'post',
        data
    })
}

// 新增编辑发送消息计划
export const updateMessagePlan = (data: any) => {
    return request({
        url: `/member/msgPaln/saveAndUpdate`,
        method: 'post',
        data
    })
}
export const saveAndUpdateNew = (data: any) => {
    return request({
        url: `/member/msgPaln/saveAndUpdateNew`,
        method: 'post',
        data
    })
}

// 查询发送消息计划详情
export const planDetail = (planId: string) => {
    return request({
        url: `/member/msgPaln/get/${planId}`,
        method: 'get',
    })
}

// 取消发送消息计划
export const canclePlan = (planId: string) => {
    return request({
        url: `/member/msgPaln/cancel/${planId}`,
        method: 'post',
    })
}

// 获取渠道详情
export const channelDetail = (channelId: string) => {
    return request({
        url: `/system/channel/getChannel/${channelId}`,
        method: 'post',
    })
}

// 删除渠道
export const deleteChannel = (channelId: string) => {
    return request({
        url: `/system/channel/delete/${channelId}`,
        method: 'post',
    })
}

// 渠道树
export const channelTree = (channelId: string) => {
    return request({
        url: `/system/channel/treeAll`,
        method: 'get',
    });
}

// 查找通道
export const findChannel = (channelId: string = '1') => {
    return request({
        url: `/system/channel/findChild/${channelId}`,
        method: 'get',
    });
}

// 获取下级节点
export const findChildChannel = (channelId: string) => {
    return request({
        url: `/system/channel/getParentsName/${channelId}`,
        method: 'get',
    });
}

// 查找渠道树层级
export const findChannelTree = (level: number) => {
    return request({
        url: `/system/channel/treeByLevel/${level}`,
        method: 'get',
    });
}
export const findChannelTreeNoDelete = () => {
    return request({
        url: `system/channel/treeNoDelete`,
        method: 'get',
    });
}

export const findImportChannelTree = () => {
    return request({
        url: `/system/channel/treeNoDelete`,
        method: 'get',
    });
}


// 当前门店渠道
export const findCurrentStoreChannelTree = () => {
    return request({
        url: `/system/resource/configShop/treeAllConfigChannel`,
        method: 'get',
    });
}

// 客资管理

// 获取loginId
export const getLoginIdForAllocationId = (data: any) => {
    return request({
        url: `/system/resourceAllocation/getLoginIdForAllocationId`,
        method: 'post',
        data,
    });
}

// 新增发送消息计划
export const saveAndUpdate = (data: any) => {
    return request({
        url: `/member/msgPaln/saveAndUpdate`,
        method: 'post',
        data,
    });
}

// 客资调配
export const gainAllocation = (data: any) => {
    return request({
        url: `/system/resourceRecyclePool/gainAllocation`,
        method: 'post',
        data,
    });
}


// 客资列表新


export const getAllocationListNew = (data: any) => {
    return request({
        url: `/system/resourceAllocation/getAllocationListNew`,
        method: 'post',
        data,
    });
}
/**
 * 在离职资源库
 */
// 门店在池用户列表
export const getShopInPoolUsers = (data: any) => {
    return request({
        url: `/recruitment/resignationResources/getShopInPoolUsers`,
        method: 'post',
        data,
    });
}


// 保护期规则配置查询
export const getProtectionPeriodRules = () => {
    return request({
        url: `/recruitment/resignationResources/getProtectionPeriodRules`,
        method: 'post',
    });
}
// 保护期规则配置
export const addOrUpdate = (data: any) => {
    return request({
        url: `/recruitment/resignationResources/addOrUpdate`,
        method: 'post',
        data,
    });
}

// 非离职保护期人数 - 用户明细列表
export const getNonResignationProtectionDetails = (data: any) => {
    return request({
        url: `/recruitment/resignationResources/getNonResignationProtectionDetails`,
        method: 'post',
        data,
    });
}

// 资源分配
export const insertResourceAllocation = (data: any) => {
    return request({
        url: `/recruitment/resignationResources/insertResourceAllocation`,
        method: 'post',
        data,
    });
}

// 所有招聘员
export const recruitmentRoles = () => {
    return request({
        url: `/recruitment/resignationResources/queryChildrenOrSelfInOrganizationWithDeptIdsAndRoles`,
        method: 'post',
    });
}

/**
 * 用户调配记录
 */
export const getResignationAllocateDetails = (data: any) => {
    return request({
        url: `/recruitment/resignationResources/getResignationAllocateDetails`,
        method: 'post',
        data,
    });
}

/**
 * 简历库
 */

// 简历列表
export const getResumePage = (data: any) => {
    return request({
        url: `/member/memberResume/getPage`,
        method: 'post',
        data,
    });
}
// 简历详情
export const getResumeDetail = (data: any) => {
    return request({
        url: `/member/memberResume/getDetail`,
        method: 'post',
        data,
    });
}
// 简历下载列表
export const zipFileList = (data: any) => {
    return request({
        url: `/member/resumeDownLoad/zipFileList`,
        method: 'post',
        data,
    });
}

// 简历下载
export const resumeDownLoadBatch = (data: any) => {
    return request({
        url: `/member/resumeDownLoad/resumeDownLoadBatch`,
        method: 'post',
        data,
    });
}

/**
 * 
 * 非常规用户(黑名单)改版
 */


/**
 * 非常规用户列表
 */



/**
 * 审批列表
 */

// 非常规用户列表
export const getBlackListNew = (data: any) => {
    return request({
        url: `/member/memberBlacklist/getBlackList`,
        method: 'post',
        data,
    });
}

// 非常规用户详情
export const getBlackListByBlacklistId = (data: any) => {
    return request({
        url: `/member/memberBlacklist/getBlackListByBlacklistId `,
        method: 'post',
        data,
    });
}

// 审批列表
export const getBlacklistApplyList = (data: any) => {
    return request({
        url: `/system/memberBlacklist/getBlacklistApplyList`,
        method: 'post',
        data,
    });
}

// 非常规类型
export const getTypeTree = (data: any = {}) => {
    return request({
        url: `/system/memberBlacklist/getTypeTree`,
        method: 'post',
        data,
    });
}

// 提交审批
export const insert = (data: any) => {
    return request({
        url: `/system/memberBlacklist/insert`,
        method: 'post',
        data,
    });
}

// 获取审批详情
export const getBlacklistApplyByApplyId = (data: any) => {
    return request({
        url: `/system/memberBlacklist/getBlacklistApplyByApplyId`,
        method: 'post',
        data,
    });
}

// 审核记录明细 
export const getApplyApproveDetailByApplyId = (data: any) => {
    return request({
        url: `/system/memberBlacklist/getApplyApproveDetailByApplyId`,
        method: 'post',
        data,
    });
}


// 审批结果
export const insertApplyApprove = (data: any) => {
    return request({
        url: `/system/memberBlacklist/insertApplyApprove`,
        method: 'post',
        data,
    });
}

/**
 * 改版运营消息
 */
// 所有页面下拉数据
export const listOption = () => {
    return request({
        url: `/operation-managment/messagePlan/listOption`,
        method: 'get',
    });
}
// 一二级标签
export const listMemberTagValue = (data: any) => {
    return request({
        url: `/operation-managment/messagePlan/listMemberTagValue`,
        method: 'post',
        data
    });
}
//第三级标签
export const listPlanTagValue = (data: any) => {
    return request({
        url: `/operation-managment/messagePlan/listPlanTagValue`,
        method: 'post',
        data
    });
}

//地理位置 - 门店企业位置
export const selectShopDepartmentInfo = () => {
    return request({
        url: `/member-datahub/memberProfile/selectShopDepartmentInfo`,
        method: 'post',
    });
}
// 创建
export const savePlan = (data: any) => {
    return request({
        url: `/operation-managment/messagePlan/savePlan`,
        method: 'post',
        data
    });
}

export const getPlanDetail = (data: any) => {
    return request({
        url: `/operation-managment/messagePlan/getPlan`,
        method: 'post',
        data
    });
}

// 消息列表
export const listPlan = (data: any) => {
    return request({
        url: `/operation-managment/messagePlan/listPlan`,
        method: 'post',
        data
    });
}

// 推送人数
export const pushNumber = (data: any) => {
    return request({
        url: `/operation-managment/messagePlan/pushNumber`,
        method: 'post',
        data
    });
}

// 编辑
export const updatePlan = (data: any) => {
    return request({
        url: `/operation-managment/messagePlan/updatePlan`,
        method: 'post',
        data
    });
}

// 消息推送转化分析
export const selectPushEffectReport = (data: any) => {
    return request({
        url: `/operation-managment/messagePlan/selectPushEffectReport`,
        method: 'post',
        data
    });
}

/**
 * 运营管理 - 渠道监控
 */

// 列表
export const channelDrainageList = (data: any) => {
    return request({
        url: `/operation/channelDrainage/list`,
        method: 'post',
        data
    });
}
// 创建引流码
export const createChannelDrainage = (data: any) => {
    return request({
        url: `/operation/channelDrainage/createChannelDrainage`,
        method: 'post',
        data
    });
}

/**
 * 运营管理 - 调查问卷(新)
 */
// 列表
export const getQuestionSurveyList = (data: any) => {
    return request({
        url: `/marketing/questionSurvey/questionSurveyList`,
        method: 'post',
        data
    });
}
// 开关调查问卷 
export const operationSurveyOnOff = (id: string) => {
    return request({
        url: `/marketing/questionSurvey/surveyOnOff`,
        method: 'post',
        data: { id }
    });
}
// 发布
export const surveyPublish = (data: any) => {
    return request({
        url: `/marketing/questionSurvey/surveyPublish`,
        method: 'post',
        data
    });
}
export const removeSurvey = (data: any) => {
    return request({
        url: `/marketing/questionSurvey/removeSurvey`,
        method: 'post',
        data
    });
}
// 新增
export const addQuestionSurvey = (data: any) => {
    return request({
        url: `/marketing/questionSurvey/addQuestionSurvey`,
        method: 'post',
        data
    });
}
// 表单详情
export const questionViewQuestionSurvey = (data: any) => {
    return request({
        url: `/marketing/questionSurvey/viewQuestionSurvey`,
        method: 'post',
        data
    });
}
// 问卷数据详情
export const questionSurveyMembers = (data: any) => {
    return request({
        url: `/marketing/questionSurvey/questionSurveyMembers`,
        method: 'post',
        data
    });
}
// 设置问题跳转
export const problemSkipSettings = (data: any) => {
    return request({
        url: `/marketing/questionSurvey/problemSkipSettings`,
        method: 'post',
        data
    });
}
// 查询单个问题调整
export const getProblemSkip = (id: string) => {
    return request({
        url: `/marketing/questionSurvey/getProblemSkip`,
        method: 'post',
        data: { id }
    });
}
// 问卷调查-所有选项跳转集合
export const getOptionSkips = (id: string) => {
    return request({
        url: `/marketing/questionSurvey/getOptionSkips`,
        method: 'post',
        data: { id }
    });
}

// 问卷调查-问卷详情问卷内容-web
export const questionMemberContent = (id: string) => {
    return request({
        url: `/marketing/questionSurvey/questionMemberContent`,
        method: 'post',
        data: { id }
    });
}


// 专题活动列表
export const listActivityThematicPage = (data: any) => {
    return request({
        url: `/operation/activityThematic/listActivityThematicPage`,
        method: 'post',
        data
    })
}

// 专题活动列表-保存
export const activityThematicSave = (data: any) => {
    return request({
        url: `/operation/activityThematic/save`,
        method: 'post',
        data
    })
}

// 专题活动列表-编辑
export const activityThematicUpdate = (data: any) => {
    return request({
        url: `/operation/activityThematic/update`,
        method: 'post',
        data
    })
}
// 专题活动列表-详情
export const getActivityThematic = (id: string) => {
    return request({
        url: `/operation/activityThematic/getActivityThematic`,
        method: 'post',
        data: { id }
    })
}

/**
 * 异常加微记录
 */
// 记录列表
export const queryExceptionRecord = (data: any) => {
    return request({
        url: `/recruitment/wecomAddRule/queryExceptionRecord`,
        method: 'post',
        data
    });
}
// 惩戒记录
export const queryPunishRecord = (data: any) => {
    return request({
        url: `/recruitment/wecomAddRule/queryPunishRecord`,
        method: 'post',
        data
    });
}
// 解除惩戒
export const cancelPunish = (data: any) => {
    return request({
        url: `/recruitment/wecomAddRule/cancelPunish`,
        method: 'post',
        data
    });
}
// 规则查询
export const queryRule = () => {
    return request({
        url: `/recruitment/wecomAddRule/query`,
        method: 'post',
    });
}
// 规则保存
export const querySave = (data: any) => {
    return request({
        url: `/recruitment/wecomAddRule/save`,
        method: 'post',
        data
    });
}




