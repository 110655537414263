/**
 * @ Author: Your name
 * @ Create Time: 2024-08-02 10:31:20
 * @ Modified by: Your name
 * @ Modified time: 2024-08-13 16:24:57
 * @ Description: 审批流
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { examineModel } from 'pages/configurationCenter/oaApproval/data';
import { deepCopy } from 'utils'


interface Iloading {
  flowList: any[];
  id: number;
  staffData: any[];
  departmentData: any[];
  roleTypeOptions: any[];
  examineId: string; // 表单ID
  batchId: string; // 表单ID
  conditionOptions: any[]; // 条件分支下拉数据
  recipientNode: any; // 抄送人数据
  groupItem: oaGroupAddOrUpdateModel | null; // 选中分组
  companyAllOptions: any[]; // 公司所有人 
}

const initialState: Iloading = {
  flowList: [deepCopy(examineModel)],
  id: 1,
  staffData: [],
  departmentData: [],
  roleTypeOptions: [],
  examineId: '',
  batchId: '',
  conditionOptions: [],
  recipientNode: {
    ...deepCopy(examineModel),
    examineType: 10,
    name: '抄送人',
    content: '直接部门负责人',
    userList: [1],
    selfCopy: 1
  },
  groupItem: null,
  companyAllOptions: [],
}


export const flowSlice = createSlice({
  name: 'flow',
  initialState,
  reducers: {
    setFlowList: (state, action: PayloadAction<any[]>) => {
      state.flowList = action.payload
    },
    resetFlowList: (state) => {
      state.flowList = [deepCopy(examineModel)];
      state.id = 1;
      state.examineId = '';
      state.batchId = '';
      state.recipientNode = {
        ...deepCopy(examineModel),
        examineType: 10,
        name: '抄送人',
        content: '直接部门负责人',
        copyParentLevels: [1],
        selfCopy: 1
      };
      // state.staffData = [];
      // state.roleTypeOptions = [];
    },
    resetOther: (state) => {
      state.groupItem = null;
      state.staffData = [];
      state.departmentData = [];
    },
    setId: (state, action: PayloadAction<number>) => {
      state.id = action.payload
    },
    // 组织架构人员数据
    setStaffData: (state, action: PayloadAction<any[]>) => {
      state.staffData = action.payload
    },
    // 组织架构部门
    setDepartmentData: (state, action: PayloadAction<any[]>) => {
      state.departmentData = action.payload
    },
    // 角色分类
    setRoleTypeOptions: (state, action: PayloadAction<any[]>) => {
      state.roleTypeOptions = action.payload
    },
    // 设置表单ID
    setExamineId: (state, action: PayloadAction<string>) => {
      state.examineId = action.payload
    },
    // 设置
    setBatchId: (state, action: PayloadAction<string>) => {
      state.batchId = action.payload
    },
    // 条件分支下拉数据
    setConditionOptions: (state, action: PayloadAction<any[]>) => {
      state.conditionOptions = action.payload
    },
    // 设置抄送人
    setRecipientNode: (state, action: PayloadAction<any[]>) => {
      state.recipientNode = action.payload
    },
    // 暂存groupId
    setGroupItem: (state, action: PayloadAction<oaGroupAddOrUpdateModel>) => {
      state.groupItem = action.payload
    },
    // 公司所有人数据
    setCompanyAllOptions: (state, action: PayloadAction<any>) => {
      state.companyAllOptions = action.payload
    },

  },
})

export const {
  setFlowList,
  setId,
  setBatchId,
  resetFlowList,
  setStaffData,
  setRoleTypeOptions,
  setExamineId,
  setConditionOptions,
  setRecipientNode,
  resetOther,
  setGroupItem,
  setDepartmentData,
  setCompanyAllOptions
} = flowSlice.actions

export default flowSlice.reducer