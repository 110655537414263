import request from "server";
// 获取账户权限数据
export const getJurisdictionData = () => {
    return request({
        url: `/transport/carApply/getCostDataStatisticsTabVO`,
        method: 'post',
        data: {}
    })
}

// 获取门市视角副总数据
export const getShopVicePresidentData = (data: object) => {
    return request({
        url: `/transport/carApply/getCostDataStatisticsByShop`,
        method: 'post',
        data
    })
}
// 财务视角
export const getCostDataStatisticsByFinance = (data: object) => {
    return request({
        url: `/transport/carApply/getCostDataStatisticsByFinance`,
        method: 'post',
        data
    })
}
// 通勤用车
export const getCostDataStatisticsByXm = (data: object) => {
    return request({
        url: `/transport/carApply/getCostDataStatisticsByXm`,
        method: 'post',
        data
    })
}
// 获取业务员数据
export const getCostDataStatisticsBySales2 = (data: object) => {
    return request({
        url: `/transport/carApply/getCostDataStatisticsBySales2`,
        method: 'post',
        data
    })
}
// 门市经理
export const getCostDataStatisticsByShop2 = (data: object) => {
    return request({
        url: `/transport/carApply/getCostDataStatisticsByShop2`,
        method: 'post',
        data
    })
}
// 业务经理 
export const getCostDataStatisticsBySales = (data: object) => {
    return request({
        url: `/transport/carApply/getCostDataStatisticsBySales`,
        method: 'post',
        data
    })
}
// 报销车费管理列表
export const getFareList = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/fareList/query`,
        method: 'post',
        data
    })
}
// 报销车费管理-批量审批
export const fareCheckBatch = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/fareCheck/batch`,
        method: 'post',
        data
    })
}
// 报销车费管理-详情
export const fareDetail = (signUpId: object) => {
    return request({
        url: `/recruitment/reimburseFare/fareDetail/${signUpId}`,
        method: 'get',
        data: {}
    })
}
// 报销车费管理-审核修改
export const fareCheck = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/fareCheck/single`,
        method: 'post',
        data
    })
}
// 发放条件配置查询
export const getGrantRule = () => {
    return request({
        url: `/recruitment/reimburseFare/grantRule/query`,
        method: 'get',
        data: {}
    })
}
// 发放条件配置保存
export const saveGrantRule = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/grantRule/save`,
        method: 'post',
        data
    })
}

// 应用范围配置查询     applyType: number;  // 0 企业 1 门店
export const getApplyRange = (data: { applyType: number | string, keyword?: string }) => {
    return request({
        url: `/recruitment/reimburseFare/ApplyRange/query`,
        method: 'post',
        data
    })
}
// 应用范围配置保存
export const saveApplyRange = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/ApplyRange/save`,
        method: 'post',
        data
    })
}

// 预警规则配置查询
export const getWarningRule = () => {
    return request({
        url: `/recruitment/reimburseFare/warningRule/query`,
        method: 'get',
        data: {}
    })
}
// 预警规则配置查询记录
export const getWarningHistory = (data: {
    pageNum: number,
    pageSize: number
}) => {
    return request({
        url: `/recruitment/reimburseFare/warningHistory/query`,
        method: 'post',
        data
    })
}
// 预警规则配置保存
export const saveWarningRule = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/warningRule/save`,
        method: 'post',
        data
    })
}

// 计费规则配置查询
export const getFeesRule = () => {
    return request({
        url: `/recruitment/reimburseFare/feesRule/query`,
        method: 'get',
        data: {}
    })
}
// 计费规则配置保存
export const saveFeesRule = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/feesRule/save`,
        method: 'post',
        data
    })
}
/**
 * 推荐费统计报表
 */
export const getReportRecommendFee = (data: object) => {
    return request({
        url: `/operation/recommendFee/getReport`,
        method: 'post',
        data
    })
}

export const getReportRecommendFeeNew = (data: object) => {
    return request({
        url: `/operation/recommendStatics/getAll`,
        method: 'post',
        data
    })
}
// 经纪人推荐费与推荐明细
export const getRecommendStaticsDetail = (data: object) => {
    return request({
        url: `/operation/recommendStatics/getDetail`,
        method: 'post',
        data
    })
}
// 漏斗 部门-岗位下拉数据
export const getRecommendPostAndEmployment = () => {
    return request({
        url: `/operation/recommendStatics/getRecommendPostAndEmployment`,
        method: 'get',
    })
}
// 推荐费用/人次跳转占比
export const getRecommendMarketingExpensesAndProportion = (data: any) => {
    return request({
        url: `/operation/recommendStatics/getRecommendMarketingExpensesAndProportion`,
        method: 'post',
        data
    })
}

/**
 * 报销规则配置 - 新
 */

export const feesRuleList = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/feesRule/List`,
        method: 'post',
        data
    })
}

export const feesRuleDetail = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/feesRule/detail`,
        method: 'post',
        data
    })
}

export const feesRuleSave = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/feesRule/save`,
        method: 'post',
        data
    })
}
// 查询车费报销基础配置
export const getFareBasicConfig = () => {
    return request({
        url: `/recruitment/reimburseFare/feesRule/getFareBasicConfig`,
        method: 'post',
    })
}
// 车费报销基础配置保存
export const saveFareBasicConfig = (data: object) => {
    return request({
        url: `/recruitment/reimburseFare/feesRule/saveFareBasicConfig`,
        method: 'post',
        data
    })
}