import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ItablistType } from 'declare'
import { ItreeMenu } from "declare";
import api from "api";
interface SelectedKey {
    selectedKey: string,
}

interface TabListType {
    tabList: Array<ItablistType>
}
interface IsliceMenu {
    menuList: ItreeMenu[],
    tabList: ItablistType[],
    selectedKey: any,
    menuLoading: boolean,
    openKeys: string[]

}
const initMenu: IsliceMenu = {
    menuList: [],
    tabList: [],
    selectedKey: "",
    menuLoading: false,
    openKeys: []
}

export const getMenuList = createAsyncThunk(
    "menu/getMenuList",
    async () => {
        try {
            const res = await api.getApiMenuList({});
            const { data } = res;
            return data;
        } catch (error) {

        }
    }
)
const menuSlice = createSlice({
    name: 'menu',
    initialState: initMenu,
    reducers: {
        /**
         * 更新选中
         * @param state 
         * @param action 
         */
        selectedKeyChange: (state, action: PayloadAction<SelectedKey>) => {
            const { selectedKey } = action.payload;
            state.selectedKey = selectedKey;
        },
        /**
         * 更新tablist数据
         * @param state 
         * @param action 
         */
        tabListChange: (state, action: PayloadAction<TabListType>) => {
            const { tabList } = action.payload;
            state.tabList = tabList;
        },
        /**
         * 更新菜单展开
         */
        updateMenuOpenKeys: (state, action: PayloadAction<string[]>) => {
            state.openKeys = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMenuList.fulfilled, (state, action) => {
            state.menuList = action.payload
        })
    }
})
export const {
    selectedKeyChange,
    tabListChange,
    updateMenuOpenKeys
} = menuSlice.actions
export default menuSlice.reducer;