import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';//更新后的写法
import zhCN from 'antd/es/locale/zh_CN';
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd';
import { store } from 'store/store'
import 'moment/locale/zh-cn';
import moment from 'moment';
import App from './App';
import './fonts/font.less';
import './index.less';
moment.locale('zh-cn');
const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN} >
      <App />
    </ConfigProvider>
  </Provider>
);

reportWebVitals();
