import request from "server";
/**
 * 工资条列表
 */
const getPaySlipList = (data: object = {}) => {
    return request({
        url: `/finance/salary/list`,
        method: 'post',
        data
    })
}
/**
 * 上传工资条
 */
const uploadPaySlip = (data: object = {}) => {
    return request({
        url: `/finance/salary/upload`,
        method: 'post',
        data
    })
}
/**
 * 工资条设置保存
 */
const paySlipSetSave = (data: object = {}) => {
    return request({
        url: `/finance/salary/config/save`,
        method: 'post',
        data
    })
}
/**
 * 工资条设置详情
 */
const searchPaySlipSet = (salaryId: string) => {
    return request({
        url: `/finance/salary/config/${salaryId}`,
        method: 'get',
        data: {}
    })
}
/**
 * 工资条删除
 */
const paySlipDelete = (salaryId: string) => {
    return request({
        url: `/finance/salary/${salaryId}`,
        method: 'delete',
        data: {}
    })
}
/**
 * 前往发送列表
 */
const paySlipDetailList = (data: object = {}) => {
    return request({
        url: `/finance/salary/detail`,
        method: 'post',
        data
    })
}
/**
 * 撤回单个员工工资
 */
const withdrawSinglePay = (detailId: string) => {
    return request({
        url: `/finance/salary/detail/revokeOne/${detailId}`,
        method: 'put',
        data: {}
    })
}
/**
 * 撤回所有员工工资
 */
const withdrawAllPaySlip = (salaryId: string) => {
    return request({
        url: `/finance/salary/detail/revokeAll/${salaryId}`,
        method: 'put',
        data: {}
    })
}
/**
 * 发送单个员工工资
 */
const sendSinglePay = (detailId: string) => {
    return request({
        url: `/finance/salary/detail/sendOne/${detailId}`,
        method: 'put',
        data: {}
    })
}
/**
 * 发送所有员工工资
 */
const sendAllPaySlip = (salaryId: string) => {
    return request({
        url: `/finance/salary/detail/sendAll/${salaryId}`,
        method: 'put',
        data: {}
    })
}
/**
 * 工资条查看详情
 */
const lookSinglePaySlip = (detailId: string) => {
    return request({
        url: `/finance/salary/detail/${detailId}`,
        method: 'get',
        data: {}
    })
}
/**
 * 保存工资条编辑详情
 */
const saveEditPaySlipDetail = (data: object = {}) => {
    return request({
        url: `/finance/salary/detail/save`,
        method: 'put',
        data: data
    })
}
/**
 * 异常工资条列表
 */
const salaryAbnormalList = (data: { dataType: string, salaryId: string }) => {
    return request({
        url: `/finance/salary/abnormalList`,
        method: 'post',
        data: data
    })
}
/**
 * 定时发送保存
 */
const setPaySlipSendTimed = (data: { salaryId: string, timedDate: string }) => {
    return request({
        url: `/finance/salary/detail/sendTimed`,
        method: 'put',
        data: data
    })
}
/**
 * 快速匹配企业
 * @param salaryId 
 * @returns 
 */
const fastMatchingCompany = (detailId: string) => {
    return request({
        url: `/finance/salary/detail/fastAdd/${detailId}`,
        method: 'put',
        data: {}
    })
}
/**
 * 导出数据
 */
const exportPaySlipData = (salaryId: string) => {
    return request({
        url: `/finance/salary/export/${salaryId}`,
        method: 'get',
        data: {}
    })
}
/**
 * 匹配企业员工 
 */
const matchingEnterpriseStaff = (data: any) => {
    return request({
        url: `/finance/salary/detail/match`,
        method: 'put',
        data
    })
}
const apiPaySlip = {
    getPaySlipList,
    uploadPaySlip,
    paySlipSetSave,
    searchPaySlipSet,
    paySlipDelete,
    paySlipDetailList,
    withdrawSinglePay,
    sendSinglePay,
    sendAllPaySlip,
    withdrawAllPaySlip,
    lookSinglePaySlip,
    saveEditPaySlipDetail,
    salaryAbnormalList,
    setPaySlipSendTimed,
    fastMatchingCompany,
    exportPaySlipData,
    matchingEnterpriseStaff
}

export default apiPaySlip
