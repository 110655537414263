import request from "server";
/**
 * 客服管理
 * @param data 
 * @returns 
 */
export const getEmplyeeList = (data: object) => {
    return request({
        url: `/workflow/borrow/historys`,
        method: 'post',
        data
    })
}
// 获取区域列表
export const getPosterAreaList = () => {
    return request({
        url: `/customer/order/getRegionList`,
        method: 'post',
    })
}

//预支审批
// 客服客户列表
export const findDepartmentAll = () => {
    return request({
        url: `/customer/department/findDepartmentAll`,
        method: 'get',
    })
}
// 用工单位/批量续签企业列表
export const getDepts = () => {
    return request({
        url: `/customer/customerService/findDepartmentsByUserToken`,
        method: 'get',
    })
}
// 获取当前部门的续签订单
export const getExtensionOrdersByDeptId = (data: any) => {
    return request({
        url: `/customer/order/getExtensionOrdersByDeptId`,
        method: 'post',
        data
    })
}
// 批量新增续签人员
export const addExtensionPersons = (data: any) => {
    return request({
        url: `/recruitment/extensionPersons/addExtensionPersons`,
        method: 'post',
        data
    })
}
// 用工单位-全部
export const getDeptsAll = () => {
    return request({
        url: `/customer/department/findDepartmentAll`,
        method: 'get',
    })
}

// 驻厂获取个性设置用工单位
export const getCustomDepts = () => {
    return request({
        url: `/system/sysUserDiyConfig/getConfig`,
        method: 'get',
    })
}
// 驻厂设置用工单位
export const setCustomDepts = (data: any) => {
    return request({
        url: `/system/sysUserDiyConfig/setConfig`,
        method: 'post',
        data
    })
}
/**
 * 我的待办
 * @param data 
 * @returns 
 */
export const getTodos = (data: object) => {
    return request({
        url: `/workflow/borrow/todos`,
        method: 'post',
        data
    })
}

// 审批
export const showApproval = (data: []) => {
    return request({
        url: `/workflow/task/form/show`,
        method: 'post',
        data
    })
}
// 保存审批
export const saveApproval = (data: Object) => {
    return request({
        url: `/workflow/task/form/save`,
        method: 'post',
        data
    })
}

export const importApproval = (data: any) => {
    return request({
        url: `/workflow/task/import/audit`,
        method: 'post',
        data
    })
}
/**
 * 我的已办
 * @param data 
 * @returns 
 */
export const getHistorys = (data: object) => {
    return request({
        url: `/workflow/borrow/historys`,
        method: 'post',
        data
    })
}

// 预支详情
export const getDetail = (id: string) => {
    return request({
        url: `/workflow/borrow/detail/${id}`,
        method: 'get',
    })
}
/**
 * 员工管理
 */

// 客服所在工厂列表
export const getFactories = (shortName: string) => {
    return request({
        url: `/recruitment/factoryMember/factories?shortName=${shortName}`,
        method: 'get',
    })
}

// /
export const getFactories2 = (shortName: string) => {
    return request({
        url: `/recruitment/factoryMember/factories2?shortName=${shortName}`,
        method: 'get',
    })
}
// 批量续签员工列表
export const getMemberList = (data: any) => {
    return request({
        url: `/recruitment/factoryMember/memberList`,
        method: 'post',
        data
    })
}
// 查询指定续签人员列表
export const chooseExtensionPerson = (data: any) => {
    return request({
        url: `/recruitment/extensionPersons/chooseExtensionPerson`,
        method: 'post',
        data
    })
}

// 离职明细列表
export const resignationDetails = (data: any) => {
    return request({
        url: `/recruitment/factoryMember/resignationDetails`,
        method: 'post',
        data
    })
}
// 查询工厂员工详情
export const getFactoriesDetail = (factoryRegisterId: string) => {
    return request({
        url: `/recruitment/factoryMember/detail/${factoryRegisterId}`,
        method: 'get',
    })
}

// 修改员工基本信息
export const updateMemberInfo = (data: any) => {
    return request({
        url: `/recruitment/factoryMember/updateMemberInfoWeb`,
        method: 'post',
        data
    })
}

// 验证银行卡
export const checkBankCard = (bankcard: string) => {
    return request({
        url: `/external-api/eApi/accountAuth/getBankcardInfo/${bankcard}`,
        method: 'get',
    })
}

// 修改员工基本信息
export const updateBaseInfo = (data: any) => {
    return request({
        url: `/recruitment/factoryMember/updateBaseInfo`,
        method: 'post',
        data
    })
}
export const delUser = (factoryRegisterId: any) => {
    return request({
        url: `/recruitment/factoryMember/deleteMemberInfoWeb/${factoryRegisterId}`,
        method: 'post',
    })
}
// 修改银行卡
export const updateBankCard = (data: any) => {
    return request({
        url: `/recruitment/factoryMember/updateBankCard`,
        method: 'post',
        data
    })
}
// 获取转在职员工列表
export const getEmployeeList = (data: any) => {
    return request({
        url: `/recruitment/newEmployee/inOfficeEmployeeList`,
        method: 'post',
        data
    })
}

// 批量转在职
export const updateEmployeeStauts = (data: any) => {
    return request({
        url: `/recruitment/newEmployee/updateBatchInOfficeEmployee`,
        method: 'post',
        data
    })
}
// 批量转离职
export const updateBatchQuitEmployee = (data: any) => {
    return request({
        url: `/recruitment/newEmployee/updateBatchQuitEmployee`,
        method: 'post',
        data
    })
}
// 批量补资料提醒
export const noticeBath = (data: any) => {
    return request({
        url: `/recruitment/receptionist/noticeBath`,
        method: 'post',
        data
    })
}
// 上传员工离职表
export const uploadEmployeeExcel = (data: any) => {
    return request({
        url: `/recruitment/newEmployee/importQuitEmployee`,
        method: 'post',
        data
    })
}
//员工管理 - 操作日志
export const getOperationLogList = (data: any) => {
    return request({
        url: `/recruitment/operationLogController/getOperationLogList`,
        method: 'post',
        data
    })
}
/**
 * 更新员工信息 
 */
export const apiImportUpdateEmployee = (data: any) => {
    return request({
        url: `/recruitment/newEmployee/importUpdateEmployee`,
        method: 'POST',
        data: data
    })
}
// 查询工作经历
export const workExperienceList = (memberId: string) => {
    return request({
        url: `/recruitment/recSignUp/getWorkExperience/${memberId}`,
        method: 'get',
    })
}
// 查询工作经历基本信息
export const workExperienceInfo = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/getWorkExperienceInfo`,
        method: 'post',
        data,
    })
}
// 员工详情借支记录攻击
export const borrowList = (departmentId: string, memberId: string) => {
    return request({
        url: `/finance/borrow/employee/sum/${departmentId}/${memberId}`,
        method: 'get',
    })
}

// 薪资查询
export const salaryList = (data: any) => {
    return request({
        url: `/finance/salary/detail/forWeb`,
        method: 'post',
        data
    })
}

// 签约主体
export const companyList = () => {
    return request({
        url: `/customer/company/findAll`,
        method: 'get',
    })
}
// 投诉列表
export const complaintList = (id: string) => {
    return request({
        url: `/system/complaint/web/getListByMemberId/${id}`,
        method: 'get',
        data: {}
    })
}



// 报到转离职
export const getDimissionList = (data: any) => {
    return request({
        url: `/recruitment/employee/reportToQuitPage`,
        method: 'post',
        data
    })
}
// 表单手机列表 
export const getFormList = (data: any) => {
    return request({
        url: `/system/formcollection/getFormCollection`,
        method: 'post',
        data
    })
}
// 新增编辑表单
export const getAddOrEdit = (data: any) => {
    return request({
        url: `/system/formcollection/updateFormCollection`,
        method: 'post',
        data
    })
}
// 查询收集表单字段
export const getFormFields = (id: number) => {
    return request({
        url: `/system/formcollection/getFormfield/${id}`,
        method: 'get',
        data: {}
    })
}

// 删除
export const operationDelForm = (id: number) => {
    return request({
        url: `/system/formcollection/deleteById/${id}`,
        method: 'post',
        data: {}
    })
}
// 表单收集word模板新增更新
export const formModuleUpdate = (data: { formId: number, wordTemplateUrl: string }) => {
    return request({
        url: `/system/formcollection/updateWordTemplate`,
        method: 'post',
        data
    })
}
// 表单收集字段新增更新
export const formFieldUpdate = (data: { formId: number, fieldJson: string }) => {
    return request({
        url: `/system/formcollection/updateFormfield`,
        method: 'post',
        data
    })
}
// 获取表单提交数据
export const getFormCollectionData = (data: { formId: number }) => {
    return request({
        url: `/system/formcollection/getFormCollectionDetails`,
        method: 'post',
        data
    })
}
// 获取查看数据表头
export const getFormCollectionTableHeader = (id: number) => {
    return request({
        url: `/system/formcollection/getFormDetailsfieldHead/${id}`,
        method: 'get',
        data: {}
    })
}
// 导出word文档
export const exportWord = (data: string[]) => {
    return request({
        url: `/system/formcollection/exportWordTemplate`,
        method: 'post',
        data
    })
}
// 批量导出图片
export const exportPicture = (data: string[]) => {
    return request({
        url: `/system/formcollection/exportPictures`,
        method: 'post',
        data
    })
}
// 导出word文档下载列表
export const downloadWordFile = (data: any) => {
    return request({
        url: `/system/formcollection/getWordFileList`,
        method: 'post',
        data
    })
}
// 输送数据简报
export const getTransportData = (data: any) => {
    return request({
        url: `/recruitment/recSignUp1/getTransportData`,
        method: 'post',
        data
    })
}
// 在离职数据简报
export const getAfterLeavingData = (data: any) => {
    return request({
        url: `/recruitment/exitStatisticsBoard/exitStatisticsBoard`,
        method: 'post',
        data
    })
}
// 在离职数据简报-明细
export const getAfterLeavingDetail = (url: string, data: {
    beginDate: string,
    endDate: string,
    employmentMode: string | null | undefined,
    dateType: number,
    viewType: number | string
}) => {
    return request({
        url: url,
        method: 'post',
        data
    })
}

// 考勤记录列表
export const attendanceGetPage = (data: any) => {
    return request({
        url: `/member-service/clockAttendanceRecord/getPage`,
        method: 'post',
        data
    })
}
// 考勤组
export const attendanceGroup = (data: any) => {
    return request({
        url: `/member-service/clockAttendance/getPage`,
        method: 'post',
        data
    })
}

// 财务管理 - 资金流水
export const fundsFlowDetails = (data: any) => {
    return request({
        url: `/member/refeeDetailRewards/fundsFlowDetails`,
        method: 'post',
        data
    })
}

export const selectAccounts = (data: any) => {
    return request({
        url: `/member/alipayAccountInfo/selectAccounts`,
        method: 'post',
        data
    })
}
// 资金流水 - 金额统计
export const fundsFlowSum = (data: any) => {
    return request({
        url: `/member/refeeDetailRewards/fundsFlowSum`,
        method: 'post',
        data
    })
}
