/**
 * @ Author: Your name
 * @ Create Time: 2023-04-03 10:45:54
 * @ Modified by: Your name
 * @ Modified time: 2024-06-25 11:12:33
 * @ Description:
 */
import { chatListModel, chatRecordModel } from "model/chatModel";
import { getChatUserList } from "api/chat";
import { formattingDate, getCookie } from "utils";
import cookie from 'js-cookie';
import dayjs from 'dayjs';

// 获取聊天列表数据 返回未读消息总数
export const getChatList = async (params?: any) => {
  return new Promise<{ data: chatListModel[], total: number, groupTotal: number }>(async (resolve, reject) => {
    let timer = setInterval(async () => {
      const userid = getCookie('userid')
      if (userid) {
        clearInterval(timer)
        try {
          const { data } = await getChatUserList({
            myId: (userid && userid) || '0',
            tagValueId: -1,
            ...params
          });
          data.forEach((item: chatListModel) => {
            const { lastestTime, lastestMsg, msgType } = item;
            if (lastestTime) {
              item['oldLastestMsg'] = lastestTime;
              let obj = toggleTime(lastestTime);
              item['lastestTime'] = obj.time as string;
            }

            if (lastestMsg) {
              item['lastestMsg'] = showLastMsgFormat(msgType, lastestMsg, item)
            }
          })

          const total = data && data.length && data.reduce((prev: number, cur: chatListModel) => {
            const { unReadCount } = cur;
            return prev + (cur?.chatType === 1 ? 0 : Number(unReadCount))  //不统计群聊的未读
          }, 0)
          const groupTotal = data && data.length && data.reduce((prev: number, cur: chatListModel) => {
            const { unReadCount } = cur;
            return prev + (cur?.chatType != 1 ? 0 : Number(unReadCount))  //群聊的未读
          }, 0)
          resolve({ data, total, groupTotal })
        } catch (error) {
          reject(error)
        }

      }
    }, 100)
  })
}
/**
 * 根据消息类型显示最近消息的格式
 */
export const showLastMsgFormat = (type?: number, lastMsg?: string, item?: chatListModel) => {
  let str = '';
  switch (type) {
    case 1:
      str = '[图片]'
      break;
    case 2:
      str = '[文件]'
      break;
    case 3:
      str = '[表情包]'
      break;
    case 4:
      str = lastMsg || ''
      break;
    case 5:
      str = '[富文本]'
      break;
    // case 6:
    //   str = '[视频]'
    //   break;
    case 7:
      str = '[链接]'
      break;
    case 13:
      str = '[报名表]'
      break;
    case 20:
      str = '[位置]'
      break;
    case 23:
      str = '[语音通话]'
      break;
    case 24:
      str = '您好，客服人员正在忙碌中，无法及时回复您的消息。为了不耽误您的时间，请拨打电话联系。'
      break;
    default:
      str = lastMsg || '';
      break;
  }
  return str;
}
/**
 * 参数
 * arr：数据数组
 * key：数组中对象的时间key键。
 * 新增属性
 * showTimeType：时间的类型
 * showTime：页面展示输出的时间
 * isShowTime：间隔上个时间是否大于5分钟，大于则显示当前时间，反之。
 **/
export const checkShowRule = (arr: chatRecordModel[]) => {
  var newArr = arr.map((item, index, array) => {
    let obj = toggleTime(item['createTime']);
    item['showTimeType'] = obj.type;
    item['showTime'] = obj.time;
    if (index > 0) {
      item['isShowTime'] = compareTimeInterval(array[index - 1]['createTime'], array[index]['createTime']);
    } else {
      item['isShowTime'] = true;
    }
    return item;
  });
  return newArr;
}
//根据不同时间的消息，输出不同的时间格式
export const toggleTime = (date: any) => {
  var time;
  var type = getDateDiff(date);
  const weekName = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  //1：新消息，2：当天消息,3：昨天消息，4：今年消息，5：其他消息
  if (type === 1) { // 当前新消息
    time = dayjs(date).format("HH:mm");//当天消息，显示：10:22
  } else if (type === 2) {
    time = dayjs(date).format("HH:mm");//当天消息，显示：10:22
  } else if (type === 3) {
    time = dayjs(date).format("昨天 HH:mm");//昨天消息，显示：昨天 20:41
  } else if (type === 4) {
    time = `${weekName[dayjs(date).day()]} ${dayjs(date).format("HH:mm")}`
  } else if (type === 5) {
    time = dayjs(date).format("MM月DD日");//其他消息，上午下午，显示：2020年11月2日 下午15:17
  } else if (type === 6) {
    time = dayjs(date).format("YYYY年MM月DD日");//其他消息，上午下午，显示：2020年11月2日 下午15:17
  }
  return {
    time: time,
    type: type
  };
}
//判断消息类型
const getDateDiff = (date: string): number => {
  var nowDate = dayjs(formattingDate(new Date()));//当前时间
  var oldDate = dayjs(date && date.split(' ')[0]);//参数时间
  const currentDate = dayjs().format('YYYY-MM-DD'); // 当前日期
  const currentYear = dayjs().format('YYYY'); // 当前年
  const currentOldYear = dayjs().format('YYYY'); // 数据年份
  const currentMsgDate = dayjs(oldDate).format('YYYY-MM-DD'); // 当前消息的日期
  const days = dayjs(nowDate).diff(oldDate, 'day'); // 当前日期与消息日期的相差天数
  const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD') // 昨天日期
  // const sevenDaysAfter = dayjs().subtract(7, 'day').format('YYYY-MM-DD') // 往前七天
  // const eightDaysAfter = dayjs().subtract(8, 'day').format('YYYY-MM-DD') // 往前8天

  var result;
  //优先判断昨天消息。今年的日期00:00的时间与上一个时间的时间戳小于1天，那就判断为昨天消息
  if (currentMsgDate === yesterday) { // 昨天消息
    result = 3;
  } else if (currentMsgDate === currentDate) { // 当天
    // 当天时间
    result = 2;
  } else if (days > 1 && days <= 7) {
    // 大于1天  小于一周
    result = 4;
  } else if (days > 7 && currentYear === currentOldYear) {
    // 当前日期往前推七天以上的 且在当年
    result = 5;
  } else if (days > 7 && currentYear !== currentOldYear) {
    // 当前日期往前推七天以上的 且在当年
    result = 6;
  } else {
    result = 1;
  }

  return result;
}

//判断两个时间差是否大于5分钟
export const compareTimeInterval = (t1: string, t2: string): boolean => {
  return Number(dayjs(t2)) - Number(dayjs(t1)) >= 300000
}

/**
 * 
 * @param list 聊天联系人列表
 * @param listId 列表friendId
 * @msgChatMsg 新消息类型  0:一对一聊天 1:群聊 2:机器人 3:一对多
 */
export const updateChatListPosition = (list: chatListModel[] = [], listId: string, msgChatType: number) => {
  // 获取新消息发送人ID
  let index = -1; // 新消息 联系人在列表的位置索引
  let crowdCount = 0; // 群聊个数
  for (let i = 0; i < list.length; i++) {
    const { friendId, chatType } = list[i]; // 联系人Id
    if (chatType === 1) {
      crowdCount += 1
    }
    // console.log(listId, 'listId', friendId, i);

    if (String(listId) === String(friendId) && chatType === 1) { // 群聊
      index = i
      break;
    }
    if (String(listId) === String(friendId) && chatType !== 1) { // 非群聊
      // if (String(listId) === String(friendId) && chatType === 0) { // 单聊
      index = i
      break;
    }
  }
  // 为第一个私聊对话 （最后一个群聊后面第一个对话）
  // 为第一个对话新消息  不做处理
  // if (index === 0 || (index === crowdCount && msgChatType === 0)) {
  if (index === 0 || (index === crowdCount && msgChatType !== 1)) {
    return list
  }

  if (msgChatType === 1) { // 新消息为群聊
    const _item: chatListModel[] = list.splice(index, 1)
    list.unshift(_item[0]);
    return list
  }

  // if (msgChatType === 0) { /// 为私聊
  if (msgChatType !== 1) { /// 为私聊
    const _item: chatListModel[] = list.splice(index, 1)
    list.splice(crowdCount, 0, _item[0]);// 移动到最后群聊的第一个位置
    return list
  }


}

//新消息第一个展示
export const updateChatListPositionNew = (list: chatListModel[] = [], sessionKey?: number) => {
  let index = list.findIndex((el: any) => el?.sessionKey === sessionKey);
  if (index > -1) {
    const _item: chatListModel[] = list.splice(index, 1)
    list.unshift(_item[0]);
  }
  return list;
}

export const sendChildPageData = (type: string, item?: chatListModel) => {
  let parmas = {
    type: 1,
    item: item,
    token: cookie.get("Authorization")
  };

  if (item?.chatType !== 1 && !getStationedUser()?.length) { //非群聊
    if (type === 'loadCustomerInfo') { // 客户信息页面加载完成
      const customerInfoIframeNode = document.getElementById('customerInfoIframe') as HTMLIFrameElement;
      // 传值给子组件
      customerInfoIframeNode?.contentWindow?.postMessage(parmas, '*');
    } else if (type === 'loadMaterial') { // 素材库加载完成
      const materialIframeNode = document.getElementById('materialIframe') as HTMLIFrameElement;
      materialIframeNode?.contentWindow?.postMessage(parmas, '*');
    }
  }
}


export const getChatPower = (
  roles: string[] = JSON.parse(getCookie('roles') as string),
  powerArr: string[] = [
    'recruitmenter',
    '4d6715b9-25a4-40b8-b755-bf98e2aa2537',
    '7130440b-6c74-43f5-a243-518793104fa5',
    'zpRecruitmenterSupervisor',
    'shopmanager',
    'customerService'

  ],
) => {
  if (!roles) return [];
  let arr: string[] = []
  powerArr.forEach(item => [
    roles.forEach(_item => {
      if (item === _item) {
        arr.push(item)
      }
    })
  ])
  return arr;
}

export const getStationedUser = () => {
  let roles: any = cookie.get('roles') || [];
  roles = JSON.parse(roles)
  // 客服
  let serviceArr = ['kfContractor', 'kfContractorDirector', 'kfContractorSupervisor', 'kfContractorAreaSupervisor'];
  if (!roles.length) return;
  return serviceArr.filter((el: any) => {
    return roles.includes(el)
  })
}

// 角色权限
export const getStationedUser2 = (serviceArr: string[]) => {
  let roles: any = cookie.get('roles') || [];
  roles = JSON.parse(roles)
  // let serviceArr = ['kfContractor', 'kfContractorDirector', 'kfContractorSupervisor', 'kfContractorAreaSupervisor'];
  if (!roles.length) return [];
  return serviceArr.filter((el: any) => {
    return roles.includes(el)
  })
}

export const getGroupIcon = (employmentMode: any) => {
  // 1430046061979045890 派遣工 1430046061979045891 正式工  1430046061979045889 外包工
  let obj = { style: { color: 'white', fontSize: '20px', fontWeight: 500, width: '40px', height: '40px', borderRadius: '50%', lineHeight: '40px', background: '' }, label: "" }
  if (employmentMode === '1430046061979045890') {
    obj.label = '派';
    obj.style.background = 'linear-gradient(180deg, #3474EF 0%, #2254F5 100%)';
    return obj;
  } else if (employmentMode === '1430046061979045891') {
    obj.label = '正';
    obj.style.background = 'linear-gradient(180deg, #FF9662 0%, #F86B24 100%)';
    return obj;
  } else if (employmentMode === '1430046061979045889') {
    obj.label = '外';
    obj.style.background = 'linear-gradient(180deg, #0ADFB9 0%, #00C9A5 100%)';
    return obj;
  }
  return null;
}
