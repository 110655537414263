/**
 * @ Author: caohenghui
 * @ Create Time: 2023-06-07 09:08:55
 * @ Modified by: Your name
 * @ Modified time: 2023-07-01 10:29:35
 * @ Description:海报管理store
 */

import { IPosterSlice, ItemplateOne, ItemplateTwo, ItemplateThree, ItemplateFour } from 'model/posterModel'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { templateOne, templateTwo, templateThree, templateFour } from "./data";
import { deepCopy } from 'utils';
const initialState: IPosterSlice = {
    planList: [],// 推广计划下拉数据
    activeTemplete: 0,
    templateOne: deepCopy(templateOne),
    templateTwo: deepCopy(templateTwo),
    templateThree: deepCopy(templateThree),
    templateFour: deepCopy(templateFour),
}

export const posterSlice = createSlice({
    name: 'poster',
    initialState,
    reducers: {
        setTemplateOneData: (state, action: PayloadAction<ItemplateOne>) => {
            state.templateOne = action.payload
        },
        setTemplateTwoData: (state, action: PayloadAction<ItemplateTwo>) => {
            let data = action.payload;

            if (data?.updateType) {
                console.log(data, 'updateType');
                data = {
                    ...data,
                    photoMatLibDetailsId: undefined, // 工厂图片
                    photoMatLibDetailsUrl: '',
                    profileMatLibDetailsId: undefined, // 企业简介
                    profileMatLibDetailsDesc: '',
                    addressMatLibDetailsId: undefined, // 企业地址
                    addressMatLibDetailsDesc: '',
                }
            }
            data['updateType'] = false;
            state.templateTwo = data;
        },
        setTemplateThreeData: (state, action: PayloadAction<ItemplateThree>) => {
            let data = action.payload;
            if (data?.updateType) {
                data = {
                    ...data,
                    photoMatLibDetailsId: undefined, // 工厂图片
                    photoMatLibDetailsUrl: '',
                    profileMatLibDetailsId: undefined, // 企业简介
                    profileMatLibDetailsDesc: '',
                    jobRequirementMatLibDetailsId: '', // 招聘要求
                    jobRequirementMatLibDetailsDesc: '',
                }
            }
            data['updateType'] = false;
            state.templateThree = data;
        },
        setTemplateFourData: (state, action: PayloadAction<ItemplateFour>) => {
            state.templateFour = action.payload
        },
        setActiveTemplete: (state, action: PayloadAction<number>) => {
            state.activeTemplete = action.payload
        },
        setPlanList: (state, action: PayloadAction<any[]>) => {
            state.planList = action.payload
        },
        setQwCode: (state, action: PayloadAction<{ type: number, url: string }>) => { // 设置多人活码
            const { type, url } = action.payload;
            switch (type) {
                case 0: // 模板1
                    state.templateOne = {
                        ...state.templateOne,
                        qrCode: url
                    }
                    break;
                case 1: // 模板2
                    state.templateTwo = {
                        ...state.templateTwo,
                        qrCode: url
                    }
                    break;
                case 2: // 模板3
                    state.templateThree = {
                        ...state.templateThree,
                        qrCode: url
                    }
                    break;
                case 3: // 模板4
                    state.templateFour = {
                        ...state.templateFour,
                        qrCode: url
                    }
                    break;
                default:
                    break;
            }
        },
        resetaAllData: (state) => {
            state.templateOne = deepCopy(templateOne);
            state.templateTwo = deepCopy(templateTwo);
            state.templateThree = deepCopy(templateThree);
            state.templateFour = deepCopy(templateFour);
            state.activeTemplete = 0;
            state.planList = []
        }
    },
})

export const {
    setTemplateOneData,
    setActiveTemplete,
    setPlanList,
    setTemplateTwoData,
    setTemplateThreeData,
    setTemplateFourData,
    setQwCode,
    resetaAllData,
} = posterSlice.actions

export default posterSlice.reducer