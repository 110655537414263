import request from "server";
/**
 * 菜单模块api
 */
const getApiMenuList = (data: object) => { // 根据权限返回树的数据
    return request({
        url: '/system/menu/treeselect',
        method: 'get',
        data
    })
}
const apiGetMenuList = (data: { sourceType: string | number }) => {
    return request({
        url: `/system/menu/list/${data.sourceType}`,
        method: 'get',
        data
    })
}
const apiDelMenu = (data: { menuId: number }) => {
    return request({
        url: `/system/menu/${data.menuId}`,
        method: 'delete',
        data
    })
}
const apiAddMenu = (data: object) => {
    return request({
        url: `/system/menu`,
        method: 'post',
        data
    })
}
const apiMenu = {
    getApiMenuList,
    apiGetMenuList,
    apiDelMenu,
    apiAddMenu
}
export default apiMenu