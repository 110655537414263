import request from "server";
import apiMenuModule from './menu'
import apiDictionaryModule from './dictionary'
import apiJurisdictionModule from './jurisdiction'
import apiNewJurisdiction from './newJurisdiction'
import apiPaySlip from './paySlip'
import newEmployee from './newEmployee'
import { Method } from "axios";
/**
 * 登录
 * @param data 
 * @returns 
 */
const login = (data: any) => {
    return request({
        url: '/auth/login',
        method: 'post',
        data
    })
}
/**
 * 扫码登录
 * @param data 
 * @returns 
 */
const scanWecom = (data: any) => {
    return request({
        url: '/auth/sso/wecom',
        method: 'post',
        data
    })
}
/**
 * 获取验证码
 * @returns 
 */
const verificationCode = (data: { phoneNumber: string, type: number }) => {
    return request({
        url: '/external-api/sms/sendSms',
        method: 'post',
        data
    })
}
/**
 * 修改密码
 * @param data 
 * @returns 
 */
const updatePwd = (data: { phone: string, code: string, newPass: string }) => {
    return request({
        url: '/system/user/changePass',
        method: 'post',
        data
    })

}
const userInfo = () => {
    return request({
        url: '/system/user/getUserInfo',
        method: 'get',
    })
}
// 获取当前用户信息
const currUserInfo = () => {
    return request({
        url: '/system/role/authUser/selectUserByRoleRecruitmenterShopDept',
        method: 'get',
    })
}
// 上传obs
const upload = (method?: Method, packageName?: string) => {
    return request({
        url: '/file/obs',
        method: method || 'get',
        data: { packageName }
    })
}
// 上传人员名单
const importPersonList = (data: any) => {
    return request({
        url: '/member/serviceMsg/importPersonList',
        method: "post",
        data
    })
}
// 获取门市下的招聘员
export const getShopRecruiter = (deptIds: string[], roleKeys?: string[]) => {
    return request({
        url: '/system/user/queryChildrenOrSelfInOrganizationWithDeptIdsAndRoles',
        method: 'post',
        data: {
            deptIds,
            roleKeys: roleKeys || [
                "recruitmenter"
            ]
        }
    })
}
// 配置中心 - 企业配置获取驻厂管理员
export const getCustomer = (data: any) => {
    return request({
        url: '/customer/customerService/queryChildrenOrSelfInOrganizationWithDeptIdsAndRoles',
        method: 'post',
        data
    })
}
// 获取全部推广计划

export const getPlanListForPoster = () => {
    return request({
        url: '/system/qwPlan/getPlanListForPoster',
        method: 'post',
        data: {
            status: 1,
        }
    })
}
// 海报管理列表 
export const getSysPosterDetails = (data: any) => {
    return request({
        url: '/system/sysPoster/getSysPosterDetails',
        method: 'post',
        data
    })
}
// 海报管理-新增海报 
export const addPoster = (data: any) => {
    return request({
        url: '/system/sysPoster',
        method: 'post',
        data
    })
}
// 海报管理-编辑海报 
export const editPoster = (data: any, posterId: string) => {
    return request({
        url: `/system/sysPoster/${posterId}`,
        method: 'PUT',
        data: { ...data, posterId }
    })
}
// 海报管理-新增海报 
export const getPosterDetail = (posterId: string) => {
    return request({
        url: `/system/sysPoster/${posterId}`,
        method: 'get',
        data: {}
    })
}
// 下载次数统计
export const downloadCountIncrement = (posterId: string) => {
    return request({
        url: `/system/sysPoster/downloadCountIncrement`,
        method: 'post',
        data: { posterId }
    })
}

// 海报素材查询 
export const getSysPosterMaterial = (data: {
    useDepartmentId: string;
    useType: number;
    title?: string;
}) => {
    return request({
        url: '/system/sysPoster/getSysPosterMaterial',
        method: 'post',
        data
    })
}
// 计提管理-获取部门人员下拉树列表
export const getSystemRecruitTreeDeptUser = (data: { month: string, roleKey: string }) => {
    return request({
        url: '/system/recruit/treeDeptUser',
        method: 'post',
        data
    })
}
// 计提管理-修改计提角色
export const getSystemRecruitUpdateRole = (data: IeditProvisionRoleParams) => {
    return request({
        url: '/system/recruit/updateRole',
        method: 'post',
        data
    })
}

// 获取省 
export const getProvinceList = (parentId: string = '') => {
    return request({
        url: '/system/region/findRegionById',
        method: 'post',
        data: { parentId }
    })
}

const api = {
    login,
    scanWecom,
    verificationCode,
    updatePwd,
    upload,
    importPersonList,
    userInfo,
    currUserInfo,
    ...apiMenuModule,
    ...apiDictionaryModule,
    ...apiJurisdictionModule,
    ...apiNewJurisdiction,
    ...apiPaySlip,
    ...newEmployee,
}

export default api