import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { tabEnum } from "../pages/marketingManagement/marketing";
import { getDeptsAll } from 'utils/common';

interface defalutModel {
    value: string;
    dataStatistics: {  //运营用户
        allMemberNum: number | string;
        expireMemberNum: number | string;
        signUpNum: number | string;
        toTransformMemberCount: number | string;
    },
    salaryDeptData: Array<any>;
}

const initialState: defalutModel = {
    value: tabEnum.invitationPrize,  //邀请有奖tab
    dataStatistics: {
        allMemberNum: 0,
        expireMemberNum: 0,
        signUpNum: 0,
        toTransformMemberCount: 0
    },
    salaryDeptData: [],
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        updateTab: (state, { payload }) => {
            state.value = payload
        },
        updateSataStatistics: (state, { payload }) => {
            state.dataStatistics = { ...state.dataStatistics, ...payload }
        },
        updateSalaryDeptData: (state, { payload }) => {
            state.salaryDeptData = payload
        },

    },
    extraReducers: (builder) => {
        builder.addCase(getDepartment.fulfilled, (state, action) => {
            state.salaryDeptData = action.payload
        })
    },
})

export const getDepartment = createAsyncThunk(
    "salary/getDepartment",
    async () => {
        try {
            const data: any = await getDeptsAll() || [];
            return data
        } catch (error) {

        }
    }
)

export const { updateTab, updateSataStatistics, updateSalaryDeptData } = commonSlice.actions

export default commonSlice.reducer