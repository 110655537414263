import { createSlice } from '@reduxjs/toolkit';
import { tabEnum } from "../pages/dormitoryServiceManagement/formConfig";

interface CurrentTab {
    value: string;
    idCardInfo: any;
}

const initialState: CurrentTab = {
    value: tabEnum.areaManagement,
    idCardInfo: null
}

export const dormitorySlice = createSlice({
    name: 'dormitory',
    initialState,
    reducers: {
        updateDormitoryTab: (state, { payload }) => {
            state.value = payload
        },
        updateIdCardInfo: (state, { payload }) => {
            state.idCardInfo = payload
        },
    },
})

export const { updateDormitoryTab, updateIdCardInfo } = dormitorySlice.actions

// export const selectTab = (state: RootState) => state.user.value

export default dormitorySlice.reducer