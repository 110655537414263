
let wsUrl = '';
let mobileStr = "https://m-hr-sit.mingzhongdata.com/";
const url = () => {
    let hostN = window.location.host;
    // let baseUrl = 'http://192.168.0.34:8080'

    // let baseUrl = 'https://api-hr.mingzhongdata.com/'  //正式环境地址
    let baseUrl = 'https://api-hr-sit.mingzhongdata.com/'  //sit环境地址
    // let baseUrl = 'https://api-hr-dev.mingzhongdata.com/'  //dev环境地址
    // let baseUrl = 'https://api-hr-uat.mingzhongdata.com/'  //uat环境地址
    wsUrl = 'api-hr-sit.mingzhongdata.com'
    if (hostN.indexOf('dev.dev.huahuihr.com') >= 0) {
        baseUrl = 'https://gateway.dev.dev.huahuihr.com/'
        wsUrl = 'gateway.dev.dev.huahuihr.com'
    } else if (hostN.indexOf('dev.huahuihr.com') >= 0) {
        baseUrl = 'https://gateway.dev.huahuihr.com/'  //sit环境地址
        wsUrl = 'gateway.dev.huahuihr.com';
    } else if (hostN.indexOf('hr.huahuihr.com') >= 0) {
        baseUrl = 'https://gateway.hr.huahuihr.com/'
        wsUrl = 'gateway.hr.huahuihr.com'
    } else if (hostN.indexOf('hr.mingzhongdata.com') >= 0) {
        // baseUrl = "https://hr.huahuihr.com/"
        baseUrl = 'https://api-hr.huahuihr.com/'
        wsUrl = 'api-hr.huahuihr.com'
    }

    return baseUrl
}

export const baseURL = (window as any).__environment_config__?.remoteServiceBaseUrl || url();
export const mobileUrl = (window as any).__environment_config__?.mobileUrl || mobileStr;
export const websocketUrl = (window as any).__environment_config__?.remoteServiceBaseUrl ? (window as any).__environment_config__?.remoteServiceBaseUrl.replace("https://", '').replace("/", '') : wsUrl;
//  'http://huahui.qicp.vip:18080/'


