/**
 * @ Author: Your name
 * @ Create Time: 2023-10-21 15:40:38
 * @ Modified by: Your name
 * @ Modified time: 2024-03-21 15:11:02
 * @ Description: 计提接口
 */

import request from "server";

// 规则列表
export const provisionRuleList = (data: Object) => {
  return request({
    url: `/customer/provisionRule/list`,
    method: 'post',
    data
  })
}
// 规则详情
export const provisionRuleDetail = (id: string) => {
  return request({
    url: `/customer/provisionRule/detail`,
    method: 'post',
    data: { id }
  })
}

// 规则保存
export const provisionRuleSave = (data: IprovisionRuleSubmitForm) => {
  return request({
    url: `/customer/provisionRule/save`,
    method: 'post',
    data
  })
}

// 计提明细列表

export const provisionRecordList = (data: IprovisionInfoSearchListModel) => {
  return request({
    url: `/recruitment/provisionRecord/provisionRecordList`,
    method: 'post',
    data
  })
}

// 计提查询
export const provisionLockInfo = (lockTime: string) => {
  return request({
    url: `/recruitment/provisionLock/lockInfo`,
    method: 'post',
    data: { lockTime }
  })
}

// 计提数据锁定 取消
export const provisionLock = (data: {
  lockTime: string;
  lockStatus: number;
}) => {
  return request({
    url: `/recruitment/provisionLock/lock`,
    method: 'post',
    data
  })
}
//
export const provisionRecordForOrder = (data: {
  id: string;
}) => {
  return request({
    url: `/recruitment/provisionRecord/provisionRecordForOrder`,
    method: 'post',
    data
  })
}

// 经理计提列表
export const getManagerProvisionRuleList = (data: Object) => {
  return request({
    url: `/customer/provisionRule/managerRule/list`,
    method: 'post',
    data
  })
}
// 经理计提详情
export const getManagerProvisionRuleDetail = (data: { ruleMonth: string, ruleYear: string }) => {
  return request({
    url: `/customer/provisionRule/managerRule/detail`,
    method: 'post',
    data
  })
}
// 是否存在经理计提配置
export const getManagerRuleExist = (data: { ruleMonth: string, ruleYear: string }) => {
  return request({
    url: `/customer/provisionRule/managerRuleExist`,
    method: 'post',
    data
  })
}
// 经理计提保存
export const saveManagerProvisionRuleDetail = (data: ImanagerRuleDetailModel) => {
  return request({
    url: `/customer/provisionRule/managerRule/saveOrUpdate`,
    method: 'post',
    data
  })
}

// 期初计提列表
export const getOpeningProvisionRuleList = (data: Object) => {
  return request({
    url: `/customer/provisionRule/startRule/list`,
    method: 'post',
    data
  })
}

// 期初计提详情
export const getOpeningProvisionRuleDetail = (data: { ruleMonth: string, ruleYear: string }) => {
  return request({
    url: `/customer/provisionRule/startRule/detail`,
    method: 'post',
    data
  })
}

// 期初计提保存
export const saveOpeningProvisionRuleDetail = (data: IprovisionOpeningRuleList) => {
  return request({
    url: `/customer/provisionRule/startRule/saveOrUpdate`,
    method: 'post',
    data
  })
}

// 查询月末在职
export const shopOnJobNumber = (data: { ruleMonth: string, ruleYear: string }) => {
  return request({
    url: `/recruitment/provisionRecord/shopOnJobNumber`,
    method: 'post',
    data
  })
}

// 员工计提-批量保存配置  
export const batchRuleSave = (data: any) => {
  return request({
    url: `/customer/provisionRule/batchRuleSave`,
    method: 'post',
    data
  })
}
// 员工计提-批量保存配置记录
export const batchRuleHistory = (data: any) => {
  return request({
    url: `/customer/provisionRule/batchRuleHistory`,
    method: 'post',
    data
  })
}
// 员工计提-查询所有集体类别以及门店数
export const queryShopCategoryAndShopCount = () => {
  return request({
    url: `/system/sysShopCategoryProvision/queryShopCategoryAndShopCount`,
    method: 'post',
    data: {}
  })
}
