import { staffStatus, sexStauts, announceStatus, repairStatus } from '../../dictionary/dictionaryId';
import { getRoomSelect, getBedSelect } from 'utils/common';
export enum tabEnum {
    areaManagement = 'areaManagement',
    dormitoryBuilding = 'dormitoryBuilding',
    roomManagement = "roomManagement",
    bedManagement = "bedManagement",
}

export const roomOperType = [
    { label: "退宿", value: 1, title: "确认退宿", name: "" },
    { label: "换宿", value: 2, title: "安排换宿", name: "" },
    { label: "换铺", value: 3, title: "安排换铺", name: "" },
]

// 申请中操作
export const roomApply = [
    { label: "确认入住", value: 1, title: "确认入住", name: "" },
    { label: "取消入住", value: 2, title: "取消入住", name: "" },
    { label: "调宿", value: 3, title: "调宿", name: "" },
]


// 宿舍楼管理
export const dormitoryBuilding = [
    { tag: "Select", key: 1, name: "regionId", "label": "", placeholder: "宿舍区域", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "buildingType", "label": "", placeholder: "宿舍类型", showSearch: true, options: [] },
    { tag: "Input", key: 3, name: "keyword", "label": "", placeholder: "宿舍楼" },
    { tag: "Search", key: 7, name: "search", "label": "查询" },
];

// 房间管理
export const roomManagement = [
    { tag: "Select", key: 1, name: "regionId", "label": "", placeholder: "宿舍区域", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "buildingId", "label": "", placeholder: "宿舍楼", showSearch: true, options: [] },
    { tag: "Select", key: 3, name: "room", "label": "", placeholder: "房间", showSearch: true, options: [] },
    { tag: "Select", key: 4, name: "type", "label": "", placeholder: "房型", showSearch: true, options: [] },
    { tag: "Select", key: 5, name: "sex", "label": "", placeholder: "性别", showSearch: true, options: [] },
    { tag: "Search", key: 7, name: "search", "label": "查询" },
];

// 床位管理
export const bedManagement = [
    { tag: "Select", key: 1, name: "area", "label": "", placeholder: "宿舍区域", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "build", "label": "", placeholder: "栋别", showSearch: true, options: [] },
    { tag: "Select", key: 3, name: "room", "label": "", placeholder: "房间", showSearch: true, options: [] },
    { tag: "Select", key: 4, name: "type", "label": "", placeholder: "状态", showSearch: true, options: [] },
    { tag: "Search", key: 7, name: "search", "label": "查询" },
];

// 住宿管理
export const accommodationManagement = [
    { tag: "Select", key: 0, name: "area1", "label": "", placeholder: "宿舍区域", showSearch: true, options: [] },
    { tag: "Select", key: 1, name: "area", "label": "", placeholder: "宿舍楼", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "build", "label": "", placeholder: "宿舍类型", showSearch: true, options: [] },
    { tag: "Select", key: 3, name: "room", "label": "", placeholder: "房型", showSearch: true, options: [] },
    { tag: "Select", key: 4, name: "type", "label": "", placeholder: "性别", showSearch: true, options: [] },
    { tag: "Input", key: 5, name: "roomNum", "label": "", placeholder: "房间号", },
    { tag: "Search", key: 7, name: "search", "label": "查询" },
]

export enum tabEnum1 {
    staffManagement = 'staffManagement',
    checkOut = 'checkOut',
    inNight = "inNight",
    dimission = "dimission",
}
export enum tabEnum2 {
    atTheConvention = 'atTheConvention',
    announcement = 'announcement',
}

// 入住公约
export const atTheConvention = [
    { tag: "Select", key: 1, name: "regionId", "label": "", placeholder: "宿舍区域", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "buildingId", "label": "", placeholder: "宿舍楼", showSearch: true, options: [] },
    { tag: "RangePicker", key: 3, name: "time", "label": "签约日期", placeholder: "签约日期" },
    { tag: "Select", key: 4, name: "status", "label": "", placeholder: "状态", showSearch: true, options: announceStatus },
    { tag: "Input", key: 5, name: "keyword", "label": "", placeholder: "姓名/身份证号", showSearch: true },
    { tag: "Search", key: 8, name: "search", "label": "查询" },
];


export enum maintainTabEnum {
    orderRepairManagement = 'orderRepairManagement',
}

export const orderRepairManagement = [
    { tag: "Select", key: 1, name: "regionId", "label": "", placeholder: "宿舍区域", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "buildingType", "label": "", placeholder: "宿舍类型", showSearch: true, options: [] },
    { tag: "RangePicker", key: 3, name: "time", "label": "申请日期", placeholder: "申请日期" },
    { tag: "Input", key: 4, name: "keyword", "label": "", placeholder: "申请人/联系电话" },
    { tag: "Search", key: 7, name: "search", "label": "查询" },
];
// 维修管理
export const repairManagement = [
    { tag: "Select", key: 1, name: "regionId", "label": "", placeholder: "报修区域", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "status", "label": "", placeholder: "订单状态", showSearch: true, options: repairStatus },
    { tag: "RangePicker", key: 3, name: "time", "label": "申请日期", placeholder: "申请日期" },
    { tag: "Input", key: 4, name: "keyword", "label": "", placeholder: "申请人/联系电话" },
    { tag: "Search", key: 7, name: "search", "label": "查询" },
];
export const getOptions = (id: string, index: number, data: any, setOptions: React.SetStateAction<any>, filter?: number, roomType?: string) => {
    if (!id) return;
    if (index === 0 && index < data.length - 1) {
        getRoomSelect({
            buildingId: id,
            filter,
            roomType,
            status: filter === 2 ? 1 : null
        }).then((res: any) => {
            let s = [...data];
            s[index + 1].options = res;
            setOptions(s)
        }).catch((error) => { console.log(error); })
    }
    if (index === 1 && index < data.length - 1) {
        getBedSelect(id, filter).then((res: any) => {
            let s = [...data];
            s[index + 1].options = res;
            setOptions(s)
        }).catch((error) => { console.log(error); })
    }
}

export const dealClear = (index: number = -1, arr: Array<any>, setOptions?: React.SetStateAction<any>,) => {
    let obj = {} as any;
    if (index < 0) {
        for (let i = 0; i < arr.length; i++) {
            let str = arr[i].name;
            obj[str] = null;
        }
    } else {
        for (let i = 0; i < arr.length; i++) {
            let str = arr[i].name;
            if (i > index) {
                obj[str] = null;
                arr[i].options = [];
            }
        }
        setOptions([...arr]);
    }
    return obj
}

// 员工费用
export enum costStaff {
    feesList = 'feesList',
}
