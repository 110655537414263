import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

interface CounterState {
  value: number,
  userInfo: any;
  signUpInfo: any;
  msgContent: any;
  firstStepData: any;
  secondStepData: any;
  thirdStepData: any;
  fourSetpData: any;
  msgListOptions: any;
}

const initialState: CounterState = {
  value: 0,
  userInfo: undefined,
  signUpInfo: undefined,
  msgContent: null,
  firstStepData: null,
  secondStepData: null,
  thirdStepData: null,
  fourSetpData: null,
  msgListOptions: {
    eventOptionList: [],
    tagList: [],
    planList: [],
    actionList: [],
    pageForwardList: [],
    userGoalsActList: [],
  }
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<{ userInfo: object }>) => {
      const { userInfo } = action.payload;
      state.userInfo = userInfo;
    },
    updateUserInfo: (state, action: PayloadAction<{ userInfo: object }>) => {
      const { userInfo } = action.payload;
      state.userInfo = userInfo;
    },
    clearUserInfo: (state) => {
      state.userInfo = undefined;
    },
    setSignUpInfo: (state, action: PayloadAction<{ signUpInfo: object }>) => {
      const { signUpInfo } = action.payload;
      state.signUpInfo = signUpInfo;
    },
    clearSignUpInfo: (state) => {
      state.signUpInfo = undefined;
    },
    updateOperMsg: (state, action: PayloadAction<{ msgContent: object }>) => {
      const { msgContent } = action.payload;
      state.msgContent = { ...state.msgContent, ...msgContent };
    },
    updateFirstData: (state, action: PayloadAction<{ data: object }>) => {
      const { data } = action.payload;
      state.firstStepData = { ...data };
    },
    updateSecondData: (state, action: PayloadAction<{ data: object }>) => {
      const { data } = action.payload;
      state.secondStepData = { ...data };
    },
    updateThirdData: (state, action: PayloadAction<{ data: object }>) => {
      const { data } = action.payload;
      state.thirdStepData = { ...data };
    },
    updateFourData: (state, action: PayloadAction<{ data: object }>) => {
      const { data } = action.payload;
      state.fourSetpData = { ...data };
    },
    clearOperMsg: (state) => {
      state.msgContent = null;
      state.firstStepData = null;
      state.secondStepData = null;
      state.thirdStepData = null;
      state.fourSetpData = null;
    },
    updateMsgListOptions: (state, action: PayloadAction<object>) => {
      state.msgListOptions = action.payload;
    },
  },
})

export const { setUserInfo, updateUserInfo, clearUserInfo, setSignUpInfo, clearSignUpInfo, updateOperMsg, updateFirstData, updateSecondData, updateThirdData, updateFourData, clearOperMsg, updateMsgListOptions } = userSlice.actions

export const selectCount = (state: RootState) => state.user.value

export default userSlice.reducer