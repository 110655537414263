import { Method } from "axios";
import request from "server";
/**
 * 字典模块
 */
const apiGetDicTypeList = (data: object) => {
    return request({
        url: `/system/dict/type/list`,
        method: 'get',
        data
    })
}
const apiAddOrEditDicType = (data: object, method?: Method) => {
    return request({
        url: `/system/dict/type`,
        method: method || 'post',
        data
    })
}


const apiDelDicList = (data: { dictId: number }) => {
    return request({
        url: `/system/dict/type/${data.dictId}`,
        method: 'delete',
        data: {}
    })
}
/**
 * 字典选项
 */
const apiGetDicList = (dictId: any) => {
    return request({
        url: `/system/dict/data/type/dictType/${dictId}`,
        method: 'get',
        data: {}
    })
}
// 通过code获取字典列表
const apiGetDicListByCode = (data: any) => {
    return request({
        url: `/system/dict/data/findDictByTypeCodes`,
        method: 'post',
        data
    })
}
const apiAddOrEditTerm = (data: object, method?: Method) => {
    return request({
        url: `/system/dict/data`,
        method: method || 'post',
        data
    })
}
const apiDelTermList = (data: { dictCode: number }) => {
    return request({
        url: `/system/dict/data/${data.dictCode}`,
        method: 'delete',
        data
    })
}
/**
 * 根据类型查询数据字典 
 */
const getMulDictData = (data: { codes: string[] }) => {
    return request({
        url: `/system/dict/data/findDictByTypeCodes`,
        method: 'post',
        data
    })
}
const apiDictionary = {
    apiGetDicTypeList,
    apiAddOrEditDicType,
    apiGetDicList,
    apiGetDicListByCode,
    apiDelDicList,
    apiAddOrEditTerm,
    apiDelTermList,
    getMulDictData
}
export default apiDictionary