/**
 * @ Author: Your name
 * @ Create Time: 2023-10-19 09:20:55
 * @ Modified by: Your name
 * @ Modified time: 2024-03-21 16:17:44
 * @ Description: 计提 slice
 */

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { formattingDate } from 'utils'
import { Iselect } from 'declare'
import { provisionLockInfo } from "api/provision";
import moment from "moment";
interface Iloading {
  treeItem: ItreeDataNode | undefined;
  month: string;
  shopData: Iselect[];
  shopTypeData: IprovisionRuleShopModel[];
  lockInfo: number;
  recruitmentCommissionMode: string;
  openingRuleDetails: IprovisionOpeningRuleList; // 期初计提规则详情
}

const initialState: Iloading = {
  treeItem: undefined, // 点击角色 存储数据
  month: moment().format('YYYY-MM'),
  shopData: [],
  shopTypeData: [],
  lockInfo: 0,
  recruitmentCommissionMode: '',
  openingRuleDetails: {
    ruleMonth: '',
    ruleYear: '',
    ruleList: []
  }
}
export const getLockInfo = createAsyncThunk(
  "provision/lockInfo",
  async (lockTime: string) => {
    try {
      const res = await provisionLockInfo(lockTime);
      const { data } = res;
      return data;
    } catch (error) {

    }
  }
)

export const provisionSlice = createSlice({
  name: 'provision',
  initialState,
  reducers: {
    // 设置保存角色数据
    setTreeItem: (state, action: PayloadAction<ItreeDataNode>) => {
      state.treeItem = action.payload
    },
    // 设置月份
    setMonth: (state, action: PayloadAction<string>) => {
      state.month = action.payload
    },
    // 设置门市数据
    setShopData: (state, action: PayloadAction<Iselect[]>) => {
      state.shopData = action.payload
    },
    // 设置门市类型数据
    setShopTypeData: (state, action: PayloadAction<IprovisionRuleShopModel[]>) => {
      state.shopTypeData = action.payload
    },
    //暂存订单提成模式
    setRecruitmentCommissionMode: (state, action: PayloadAction<string>) => {
      state.recruitmentCommissionMode = action.payload
    },
    // 更新期初计提数据
    setOpeningRuleDetails: (state, action: PayloadAction<IprovisionOpeningRuleList>) => {
      state.openingRuleDetails = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLockInfo.fulfilled, (state, action) => {
      state.lockInfo = action.payload
    })
  }
})
export const {
  setTreeItem,
  setMonth,
  setShopData,
  setShopTypeData,
  setRecruitmentCommissionMode,
  setOpeningRuleDetails,
} = provisionSlice.actions

export default provisionSlice.reducer
