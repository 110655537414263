import { createSlice } from '@reduxjs/toolkit'
import { siteTabEnum } from '../dictionary/sitTab'
interface CurrentTab {
    value: string;
    longConnection: number;
    equipmentConnection: number;
    idCardData: any;
    brushQueryData: any;
    entryModal: boolean;
    entryData: any;
}

const initialState: CurrentTab = {
    value: siteTabEnum.signup,
    longConnection: 0,
    equipmentConnection: 0,
    idCardData: null,
    brushQueryData: null,
    entryModal: false,
    entryData: null,
}

export const siteTabSlice = createSlice({
    name: 'sitTab',
    initialState,
    reducers: {
        updateTab: (state, { payload }) => {
            state.value = payload
        },
        updateConnection: (state, { payload }) => {
            state.longConnection = payload
        },
        updateEquipment: (state, { payload }) => { //刷证设备连接
            state.equipmentConnection = payload
        },
        updateIdCardData: (state, { payload }) => {
            state.idCardData = payload
        },
        updateQueryData: (state, { payload }) => {
            state.brushQueryData = payload
        },
        updateEntryModal: (state, { payload }) => {
            state.entryModal = payload;
        },
        updateEntryData: (state, { payload }) => {
            state.entryData = payload;
        },
    },
})

export const { updateTab, updateConnection, updateEquipment, updateIdCardData, updateQueryData, updateEntryModal, updateEntryData } = siteTabSlice.actions

// export const selectTab = (state: RootState) => state.user.value

export default siteTabSlice.reducer