import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getJurisdictionData } from 'api/fare'
import { formattingDate } from 'utils';

interface IinitParmas {
    tabsData: any;
    params: any;
    showPage: number[];
    loading: boolean;
    activeCurrentDate: number;
    activityTab: number;
}
const initParmas: IinitParmas = {
    tabsData: undefined,
    params: {
        dateViewType: 1,
        dateViewTypeTwo: 1, // 一个页面同同时使用两次组件使用
        startDate: formattingDate(new Date()),
        endDate: formattingDate(new Date()),
        startDateTwo: formattingDate(new Date()),
        endDateTwo: formattingDate(new Date()),
        viewType: 0,
        // xId: '2136815199', // 门市id
        // xId: '1457642321639428097', // 业务员id
        xId: null,
        activeKey: 'costStatistics', // tabs页
        signUpStatus: 0, // 求职状态
        postId: -1, // 漏斗图 id
        employmentMode: -1,
        trendChartType: 0, // 0 应用费用 1 实发费用
        approveStatus: null

    },
    showPage: [],
    activeCurrentDate: 1,
    loading: false,
    activityTab: 1,
}


const fareStatisticsSlice = createSlice({
    name: 'menu',
    initialState: initParmas,
    reducers: {
        // 更新查询参数
        updateParams: (state, action: PayloadAction<any>) => {
            state.params = action.payload;
        },
        // loading
        updateLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateShowPage: (state, action: PayloadAction<any>) => {
            state.showPage = action.payload;
        },
        updateActiveCurrentDate: (state, action: PayloadAction<number>) => {
            state.activeCurrentDate = action.payload;
        },
        updateActivityTab: (state, { payload }) => {
            state.activityTab = payload;
        },
        // 更新查询参数
        resetParams: (state) => {
            state.params = {
                dateViewType: 1,
                dateViewTypeTwo: 1, // 一个页面同同时使用两次组件使用
                startDate: formattingDate(new Date()),
                endDate: formattingDate(new Date()),
                startDateTwo: formattingDate(new Date()),
                endDateTwo: formattingDate(new Date()),
                viewType: 0,
                xId: null,
                activeKey: 'costStatistics', // tabs页
            }
        },
        // 推荐费统计报表更新查询参数
        resetParams2: (state, action: PayloadAction<any>) => {
            state.params = action.payload;
        },
    },
    extraReducers: (builder) => {
        // 获取tabs数据
        builder.addCase(getTabsData.fulfilled, (state, action) => {
            let { salesMap, shopsMap, viewTypeList } = action.payload;
            let tabsData: any = {
                salesMap: [],
                shopsMap: [],
                viewTypeList
            };
            if (salesMap) {
                for (const key in salesMap) {
                    if (Object.prototype.hasOwnProperty.call(salesMap, key)) {
                        const element = salesMap[key];
                        tabsData?.salesMap.push({
                            label: element,
                            value: key
                        })
                    }
                }
            }
            if (shopsMap) {
                for (const key in shopsMap) {
                    if (Object.prototype.hasOwnProperty.call(shopsMap, key)) {
                        const element = shopsMap[key];
                        tabsData?.shopsMap.push({
                            label: element,
                            value: key
                        })
                    }
                }
            }
            let _showPage: number[] = []
            let xId = null;

            if (tabsData?.shopsMap.length > 1 && viewTypeList && viewTypeList.indexOf(1) !== -1) { // 门市副总 门市下拉数据大于1 viewTypeList存在1
                _showPage.push(1);
            }
            if (tabsData?.salesMap.length > 1 && viewTypeList && viewTypeList.indexOf(2) !== -1) { // 业务副总 业下拉数据大于1 viewTypeList存在1
                _showPage.push(2);
            }
            if ((!tabsData?.salesMap.length && !tabsData?.shopsMap.length) || (viewTypeList && viewTypeList.indexOf(3) !== -1)) { // 没有门市 业务员下拉数据 则为财务视角
                _showPage.push(3);
            }
            if ((!tabsData?.salesMap.length && !tabsData?.shopsMap.length) || (viewTypeList && viewTypeList.indexOf(4) !== -1)) { // 没有门市 业务员下拉数据 存在4 为通勤视角
                _showPage.push(6);
            }
            if (tabsData?.shopsMap.length === 1 && viewTypeList && viewTypeList.indexOf(1) !== -1) { // 门市经理 
                _showPage.push(4);
                xId = tabsData?.shopsMap[0]?.value;
            }
            // 雷总数据处理
            if ((tabsData?.salesMap.length === 1 && String(tabsData?.salesMap[0]?.value) !== '1457644108471652353') && (viewTypeList && viewTypeList.indexOf(2) !== -1)) { // 业务员 
                _showPage.push(5);
                if (!xId) {
                    xId = tabsData?.salesMap[0]?.value;
                }
            }
            // 雷总数据处理
            if (tabsData?.salesMap.length === 1 && String(tabsData?.salesMap[0]?.value) === '1457644108471652353') {
                _showPage.push(5);
            }
            state.showPage = _showPage;
            state.tabsData = tabsData;

            state.params = {
                ...state.params,
                viewType: state.params?.viewType || action.payload?.viewTypeList[0],
                xId: state.params?.xId || xId
                // viewType: 2
            }

            state.loading = false
        })
    }
})

export const getTabsData = createAsyncThunk(
    "menu/getTabsData",
    async () => {
        const res = await getJurisdictionData();
        const { data } = res;
        return data;
    }
)
export const { updateParams, updateLoading, updateShowPage, updateActiveCurrentDate, resetParams, resetParams2, updateActivityTab } = fareStatisticsSlice.actions
export default fareStatisticsSlice.reducer;