/**
 * @ Author: Your name
 * @ Create Time: 2023-03-31 14:30:38
 * @ Modified by: Your name
 * @ Modified time: 2024-06-25 09:39:47
 * @ Description:
 */

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from "components/privateRoute";
import { setCustomShow } from 'store/dictionarySlice'
import { useAppDispatch } from 'hooks/hooks'
import { lazy, Suspense } from 'react';
import { debounce } from "lodash";
import 'antd/dist/antd.less';
import React from "react";
import { Spin } from 'antd';


const Login = lazy(() => import('pages/login'));
const Layout = lazy(() => import('pages/layout'));

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const handleMouseLeftDown = (event: { button: number; }) => {
    if (event.button === 0 && (window.location.pathname === '/layout/dictionary')) {
      dispatch(setCustomShow(false));
    }
  }
  const _ = (window as any).ResizeObserver;
  (window as any).ResizeObserver = class ResizeObserver extends _ {
    constructor(callback: any) {
      callback = debounce(callback, 20)
      super(callback);
    }
  }
  return (
    <div onClick={handleMouseLeftDown}>
      <Suspense fallback={<div className='full_screen'>
        <Spin size='large' />
      </div>}>
        <BrowserRouter>
          <Switch>
            <PrivateRoute path="/layout" component={Layout}></PrivateRoute>
            <Route path="/" component={Login}></Route>
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
