import request from "server";

/***
 * 宿服管理
 */

// 区域管理
export const dormitoryAreaList = (data: any) => {
    return request({
        url: `/dormitory/regionInfo/getPage`,
        method: 'post',
        data
    })
}
// 删除区域
export const dormitoryAreaDel = (data: any) => {
    return request({
        url: `/dormitory/regionInfo/delete`,
        method: 'post',
        data
    })
}
// 区域详情
export const dormitoryAreaDetail = (data: any) => {
    return request({
        url: `/dormitory/regionInfo/detail`,
        method: 'post',
        data
    })
}

// 关联企业部门
export const findDepartmentAll = () => {
    return request({
        url: `/customer/department/findDepartmentAll`,
        method: 'get',
    })
}
// 区域禁用
export const dormitoryAreaDis = (data: any) => {
    return request({
        url: `/dormitory/regionInfo/disable`,
        method: 'post',
        data
    })
}

// 区域启用
export const dormitoryAreaEna = (data: any) => {
    return request({
        url: `/dormitory/regionInfo/enable`,
        method: 'post',
        data
    })
}

// 二维码数据
export const dormitoryQrCode = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInApply/getRegionCodeData`,
        method: 'post',
        data
    })
}

// 新增/编辑保存
export const dormitoryAreaEdit = (data: any) => {
    return request({
        url: `/dormitory/regionInfo/save`,
        method: 'post',
        data
    })
}

// 下拉
export const dormitoryAreaSelect = (data: any = {}) => {
    return request({
        url: `/dormitory/regionInfo/getSelect`,
        method: 'post',
        data
    })
}

// 区域下拉过滤
export const dormitoryAreaSelectFilter = (data: any = {}) => {
    return request({
        url: `/dormitory/regionInfo/getSelectFilterManager`,
        method: 'post',
        data
    })
}
// 管理人员
export const managerByRoleList = (roleKeys: string) => {
    return request({
        url: `/system/role/authUser/selectUserSummaryByRoleKeys/${roleKeys}`,
        method: 'get',
    })
}
// 维修工
export const managerList = (data: any) => {
    return request({
        url: `/system/role/authUser/selectUserSummaryByRoleKeysPost`,
        method: 'post',
        data,
    })
}
/**
 * 宿舍楼栋管理
 */

// 列表
export const dormitoryBuildList = (data: any) => {
    return request({
        url: `/dormitory/buildingInfo/getPage`,
        method: 'post',
        data
    })
}
// 删除
export const dormitoryBuildDel = (data: any) => {
    return request({
        url: `/dormitory/buildingInfo/delete`,
        method: 'post',
        data
    })
}
// 详情
export const dormitoryBuildDetail = (data: any) => {
    return request({
        url: `/dormitory/buildingInfo/detail`,
        method: 'post',
        data
    })
}
// 禁用
export const dormitoryBuildDis = (data: any) => {
    return request({
        url: `/dormitory/buildingInfo/disable`,
        method: 'post',
        data
    })
}

// 启用
export const dormitoryBuildEna = (data: any) => {
    return request({
        url: `/dormitory/buildingInfo/enable`,
        method: 'post',
        data
    })
}

// 新增/编辑保存
export const dormitoryBuildEdit = (data: any) => {
    return request({
        url: `/dormitory/buildingInfo/save`,
        method: 'post',
        data
    })
}

// 下拉
export const dormitoryBuildSelect = (regionId: string, data = {}) => {
    return request({
        url: `/dormitory/buildingInfo/getSelectByRegionId`,
        method: 'post',
        data: { regionId, ...data }
    })
}

/**
 * 房间管理
 */

// 列表
export const dormitoryRoomList = (data: any) => {
    return request({
        url: `/dormitory/roomInfo/getPage`,
        method: 'post',
        data
    })
}
// 删除
export const dormitoryRoomDel = (data: any) => {
    return request({
        url: `/dormitory/roomInfo/delete`,
        method: 'post',
        data
    })
}
// 批量删除
export const dormitoryRoomBatchDel = (data: any) => {
    return request({
        url: `/dormitory/roomInfo/deleteBatch`,
        method: 'post',
        data
    })
}
// 详情
export const dormitoryRoomDetail = (roomId: string) => {
    return request({
        url: `/dormitory/roomInfo/detail`,
        method: 'post',
        data: { roomId }
    })
}
// 禁用
export const dormitoryRoomDis = (data: any) => {
    return request({
        url: `/dormitory/roomInfo/disable`,
        method: 'post',
        data
    })
}

// 启用
export const dormitoryRoomEna = (data: any) => {
    return request({
        url: `/dormitory/roomInfo/enable`,
        method: 'post',
        data
    })
}

// 新增/编辑保存
export const dormitoryRoomEdit = (data: any) => {
    return request({
        url: `/dormitory/roomInfo/save`,
        method: 'post',
        data
    })
}

// 下拉
// buildingId: string, filter = 1, roomType?: string
export const dormitoryRoomSelect = (data = {}) => {
    return request({
        url: `/dormitory/roomInfo/getSelectByBuildingId`,
        method: 'post',
        data
    })
}
export const dormitoryRoomImport = (data: any) => {
    return request({
        url: `/dormitory/roomInfo/import`,
        method: 'post',
        data,
    })
}
export const dormitoryRoomDownloadQRCode = (data: any) => {
    return request({
        url: `/dormitory/roomInfo/downloadQrCode`,
        method: 'post',
        data,
    })
}


/**
 * 床位管理
 */

// 列表
export const dormitoryBedList = (data: any) => {
    return request({
        url: `/dormitory/bedInfo/getPage`,
        method: 'post',
        data
    })
}
// 删除
export const dormitoryBedDel = (data: any) => {
    return request({
        url: `/dormitory/bedInfo/delete`,
        method: 'post',
        data
    })
}

// 批量删除
export const dormitoryBedBatchDel = (data: any) => {
    return request({
        url: `/dormitory/bedInfo/deleteBatch`,
        method: 'post',
        data
    })
}

// 下拉
export const dormitoryBedSelect = (roomId: string, filter = 1) => {
    return request({
        url: `/dormitory/bedInfo/getSelectByRoomId`,
        method: 'post',
        data: { roomId, filter }
    })
}

export const dormitoryBedDownloadQRCode = (data: any) => {
    return request({
        url: `/dormitory/bedInfo/downloadQrCode`,
        method: 'post',
        data,
    })
}

// 是否禁用床位
export const dormitoryBedEna = (data: any) => {
    return request({
        url: `/dormitory/bedInfo/enable`,
        method: 'post',
        data
    })
}
// 编辑床位
export const dormitoryBedEdit = (data: any) => {
    return request({
        url: `/dormitory/bedInfo/edit`,
        method: 'post',
        data
    })
}

/**
 * 住宿管理
 * 
 */

// 入驻登记 - 确认入驻
export const accommodationRegister = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/confirmCheckIn`,
        method: 'post',
        data,
    })
}

// 入驻登记 - 登记名单删除
export const accommodationDelRegister = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/deleteBedInfo`,
        method: 'post',
        data,
    })
}

// 入住登记刷卡提交验证
export const accommodationSubmitValidation = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/submitValidation`,
        method: 'post',
        data,
    })
}

export const accommodationBedToFree = () => {
    return request({
        url: `/dormitory/dormitoryin/bedToFree`,
        method: 'post',
    })
}

// 入住登记 - 登记名单 - 换宿舍
export const accommodationUpdateInNight = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/updateInNight`,
        method: 'post',
        data,
    })
}

// 入住登记-没有到厂信息-确认安排
export const accommodationNoFactoryConfirm = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/noFactoryConfirm`,
        method: 'post',
        data,
    })
}

// 宿舍管理-房态图-房间床位详情-安排入住-没有到厂，确认安排
export const accommodationDormitoryInAddConfirm = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/dormitoryInAddConfirm`,
        method: 'post',
        data
    })
}

// 宿舍管理-房态图-房间状态条件
export const accommodationRoomStatusConditions = () => {
    return request({
        url: `/dormitory/dormitoryin/roomStatusConditions`,
        method: 'get',
    })
}

// 列表
export const accommodationRoomMapSum = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/roomMapSum`,
        method: 'post',
        data,
    })
}

// 今日房间安排
export const todayRoomSet = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInApply/todayRoomSet`,
        method: 'post',
        data,
    })
}
// 重置今日安排
export const todayRoomSetReset = (regionId: string) => {
    return request({
        url: `/dormitory/dormitoryInApply/todayRoomSetReset?regionId=${regionId}`,
        method: 'get',
    })
}
// 房间详情
export const accommodationRoomBedDetails = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/roomBedDetails`,
        method: 'post',
        data,
    })
}

// 退宿
export const accommodationRoomBedToOut = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/roomBedToOut`,
        method: 'post',
        data,
    })
}

// 换宿
export const accommodationRoomBedToChange = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/roomBedToChange`,
        method: 'post',
        data,
    })
}

// 宿舍管理-房态图-房间床位详情-换宿-原宿舍信息
export const accommodationInIdmBedDetails = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/inIdmBedDetails`,
        method: 'post',
        data,
    })
}

// 换铺
export const accommodationRoomToChangeBed = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/roomToChangeBed`,
        method: 'post',
        data,
    })
}
// 安排入住
export const accommodationDormitoryInAdd = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/dormitoryInAdd`,
        method: 'post',
        data,
    })
}

// 清空临时占位
export const accommodationWebBedToFree = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInApply/batchCancelApply`,
        method: 'post',
        data,
    })
}

// 信息录入---------------

// 保存
export const saveCommitInput = (data: any) => {
    return request({
        url: `/dormitory/informationInput/commitInput`,
        method: 'post',
        data,
    })
}
// 查询
export const queryInputlist = (data: any) => {
    return request({
        url: `/dormitory/informationInput/inputlist`,
        method: 'post',
        data,
    })
}
// 删除
export const removeInput = (data: any) => {
    return request({
        url: `/dormitory/informationInput/removeInput`,
        method: 'post',
        data,
    })
}

// ---------------
/*
 * 入住人员
 */
// 入住人员列表
export const getDormitoryinList = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/list`,
        method: 'post',
        data
    })
}
// 历史入住记录列表
export const historyInList = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/historyInList`,
        method: 'post',
        data
    })
}

// 批量导离职
export const dimissionImportUpdateIn = (data: any) => {
    return request({
        url: `/dormitory/dormitoryin/importUpdateIn`,
        method: 'post',
        data
    })
}
/**
 * 退宿申请
 */
// 退宿申请列表
export const getCheckOutApplyList = (data: any) => {
    return request({
        url: `/dormitory/checkOutApply/list`,
        method: 'post',
        data
    })
}
// 确认退宿
export const checkUpdateToStatus = (data: any) => {
    return request({
        url: `/dormitory/checkOutApply/updateToStatus`,
        method: 'post',
        data
    })
}
// 退宿申请详情
export const webOutApplyDetails = (data: any) => {
    return request({
        url: `/dormitory/checkOutApply/webOutApplyDetails`,
        method: 'post',
        data
    })
}


/**
 * 换宿申请
 */
// 换宿申请列表
export const getDormitoryChangeApplyList = (data: any) => {
    return request({
        url: `/dormitory/dormitoryChangeApply/list`,
        method: 'post',
        data
    })
}
// 换宿申请-详情
export const webchangeApplyDetails = (data: any) => {
    return request({
        url: `/dormitory/dormitoryChangeApply/webchangeApplyDetails`,
        method: 'post',
        data,
    })
}
// 换宿申请-取消订单
export const webCancalChangeApply = (data: any) => {
    return request({
        url: `/dormitory/dormitoryChangeApply/webCancalChangeApply`,
        method: 'post',
        data,
    })
}
// 换宿申请-原宿舍信息
export const inNightOldBedInfo = (data: any) => {
    return request({
        url: `/dormitory/dormitoryChangeApply/oldBedInfo`,
        method: 'post',
        data,
    })
}
// 换宿申请-换宿
export const webchangeApplyCommit = (data: any) => {
    return request({
        url: `/dormitory/dormitoryChangeApply/webchangeApplyCommit`,
        method: 'post',
        data,
    })
}
// 换宿申请-换铺
export const webchangeBedCommit = (data: any) => {
    return request({
        url: `/dormitory/dormitoryChangeApply/webchangeBedCommit`,
        method: 'post',
        data,
    })
}
// 换宿申请-安排入住
export const webchangePutIn = (data: any) => {
    return request({
        url: `/dormitory/dormitoryChangeApply/webchangePutIn`,
        method: 'post',
        data,
    })
}
/**
 * 离职未退
 */
// 离职未退列表
export const getQuitNotApplyList = (data: any) => {
    return request({
        url: `/dormitory/quitNotApply/list`,
        method: 'post',
        data
    })
}
// 离职未退确认退宿
export const applyUpdateToStatus = (id: any) => {
    return request({
        url: `/dormitory/quitNotApply/updateToStatus?inId=${id}`,
        method: 'post'
    })
}
// 离职未退批量确认退宿
export const applyUpdateToStatusBatch = (data: any) => {
    return request({
        url: `/dormitory/quitNotApply/updateToStatusBatch`,
        method: 'post',
        data
    })
}
/*
 * 服务管理
 */

// 入住公约列表
export const getDormitoryInPact = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInPact/getPage`,
        method: 'post',
        data
    })
}
// 入住公约列表
export const dormitoryInPactDetail = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInPact/detail`,
        method: 'post',
        data
    })
}
// 宿舍公告列表
export const getDormitoryNotice = (data: any) => {
    return request({
        url: `/dormitory/dormitoryNotice/getPage`,
        method: 'post',
        data
    })
}
// 启用宿舍公告
export const dormitoryNoticeEnable = (data: any) => {
    return request({
        url: `/dormitory/dormitoryNotice/enable`,
        method: 'post',
        data
    })
}
// 禁用宿舍公告
export const dormitoryNoticeDisable = (data: any) => {
    return request({
        url: `/dormitory/dormitoryNotice/disable`,
        method: 'post',
        data
    })
}
// 删除宿舍公告
export const dormitoryNoticeDel = (data: any) => {
    return request({
        url: `/dormitory/dormitoryNotice/delete`,
        method: 'post',
        data
    })
}
// 推送宿舍公告
export const dormitoryNoticeSend = (data: any) => {
    return request({
        url: `/dormitory/dormitoryNotice/send`,
        method: 'post',
        data
    })
}
// 宿舍公告详情
export const dormitoryNoticeDetail = (data: any) => {
    return request({
        url: `/dormitory/dormitoryNotice/detail`,
        method: 'post',
        data
    })
}
// 获取区域楼栋级联数据
export const buildingTree = () => {
    return request({
        url: `/dormitory/regionInfo/getTree`,
        method: 'post',
    })
}
// 新增宿舍公告
export const dormitoryNoticeSave = (data: any) => {
    return request({
        url: `/dormitory/dormitoryNotice/save`,
        method: 'post',
        data
    })
}

/***
 * 维修管理
 */

export const repairList = (data: any) => {
    return request({
        url: `/dormitory/repairOrder/getPage`,
        method: 'post',
        data
    })
}

// 新增报修单
export const repairApply = (data: any) => {
    return request({
        url: `/dormitory/repairOrder/appy`,
        method: 'post',
        data
    })
}
// 详情
export const repairDetail = (data: any) => {
    return request({
        url: `/dormitory/repairOrder/detailManagement`,
        method: 'post',
        data
    })
}

// 委派维修工
export const repairAssign = (data: any) => {
    return request({
        url: `/dormitory/repairOrder/assign`,
        method: 'post',
        data
    })
}

// 取消维修
export const repairCancle = (data: any) => {
    return request({
        url: `/dormitory/repairOrder/cancel`,
        method: 'post',
        data
    })
}

// 维修检查
export const repairComplete = (data: any) => {
    return request({
        url: `/dormitory/repairOrder/complete`,
        method: 'post',
        data
    })
}

/**
 * 违纪管理
 */

// 违纪记录列表
export const violationsList = (data: any) => {
    return request({
        url: `/dormitory/violationsRegister/webList`,
        method: 'post',
        data
    })
}

// 详情
export const violationsDetails = (data: any) => {
    return request({
        url: `/dormitory/violationsRegister/registerDetails`,
        method: 'post',
        data
    })
}

// 处理结果
export const violationsUpdate = (data: any) => {
    return request({
        url: `/dormitory/violationsRegister/updateById`,
        method: 'post',
        data
    })
}

// 删除
export const violationsDelete = (data: any) => {
    return request({
        url: `/dormitory/violationsRegister/removeById`,
        method: 'post',
        data
    })
}


// 违规类型
export const violationsTypeList = (data: any) => {
    return request({
        url: `/dormitory/violationsType/list`,
        method: 'post',
        data
    })
}

// 违规类型添加
export const violationsTypeAdd = (data: any) => {
    return request({
        url: `/dormitory/violationsType/addType`,
        method: 'post',
        data
    })
}

// 违规类型更新
export const violationsTypeUpdate = (data: any) => {
    return request({
        url: `/dormitory/violationsType/updateType`,
        method: 'post',
        data
    })
}

// 违规类型删除
export const violationsTypeDelete = (data: any) => {
    return request({
        url: `/dormitory/violationsType/deleteType`,
        method: 'post',
        data
    })
}

/**
 * 入住申请
 */

// 入住申请列表
export const inApplylist = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInApply/inApplylist`,
        method: 'post',
        data
    })
}

// 批量入住
export const batchConfirmApply = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInApply/batchConfirmApply`,
        method: 'post',
        data
    })
}
// 确认入住
export const confirmApply = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInApply/confirmApply`,
        method: 'post',
        data
    })
}

// 取消入住
export const cancelApply = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInApply/cancelApply`,
        method: 'post',
        data
    })
}

// 确认调宿
export const appChangeInApply = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInApply/appChangeInApply`,
        method: 'post',
        data
    })
}

// 入住详情
export const applyDetails = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInApply/applyDetails`,
        method: 'post',
        data
    })
}

export const importUpdateIn = (data: any) => {
    return request({
        url: `/dormitory/dormitoryInApply/importUpdateIn`,
        method: 'post',
        data,
    })
}