import { Route, withRouter, RouteComponentProps } from 'react-router-dom'
interface routeArgs extends RouteComponentProps {
    path: string,
    component: React.LazyExoticComponent<any>,
    exact?: boolean,
    strict?: boolean,
    replace?: boolean,
}
const PrivateRoute: React.FC<any> = ({ component: Component, ...rest }) => {

    return <Route {...rest} render={
        (props) => {
            const { pathname } = props?.location;
            window.oncontextmenu = () => {
                if (pathname === '/layout/dictionary') {
                    return false;
                } else {
                    return true;
                }
            }
            return <Component {...props} />
        }
    }></Route>

}
export default withRouter(PrivateRoute)