import { createSlice } from '@reduxjs/toolkit';
import { tabEnum } from "../pages/transportManagement/formConfig";

interface CurrentTab {
    value: string;
    defaultValue: string;
    costDefaultValue: string;
    defaultSonValue: string;
}

const initialState: CurrentTab = {
    value: tabEnum.carRecord,
    defaultValue: "1",
    costDefaultValue: "1",
    defaultSonValue: "1",
}

export const transportSlice = createSlice({
    name: 'transport',
    initialState,
    reducers: {
        updateTab: (state, { payload }) => {
            state.value = payload
        },
        updateDefaultValue: (state, { payload }) => {
            state.defaultValue = payload
        },
        updateCostDefaultValue: (state, { payload }) => {
            state.costDefaultValue = payload
        },
        updateDefaultSonValue: (state, { payload }) => {
            state.defaultSonValue = payload
        },
    },
})

export const { updateTab, updateDefaultValue, updateCostDefaultValue, updateDefaultSonValue } = transportSlice.actions

// export const selectTab = (state: RootState) => state.user.value

export default transportSlice.reducer