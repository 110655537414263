import { appKey, version } from 'server/baseArgs'
import { getCurrentTimeStamp, getSign } from 'utils'
import { baseURL } from './baseURL'
import cookie from 'js-cookie'
import { message, Modal } from 'antd'
import axios, { Method } from 'axios'
import qs from 'qs'

const server = axios.create({
    baseURL: baseURL,
    timeout: 100000,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
});

// 请求拦截器 
server.interceptors.request.use((config: any) => {
    const { data } = config;
    const token = cookie.get("Authorization");
    config.headers.timestamp = getCurrentTimeStamp();
    config.headers.appKey = appKey;
    config.headers.version = version;
    // config.headers.salt = salt;
    config.headers.sign = getSign(JSON.stringify(data))
    config.headers.extra = '' // 扩展字段
    if (config.headers.ContentType) {
        config.headers['Content-Type'] = config.headers.ContentType
    }
    // token
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    if (data && data.FormData) {
        config.transformRequest = [
            function (_data: any) {
                //由于使用的 form-data传数据所以要格式化
                delete data.Authorization;
                _data = qs.stringify(_data);
                return data;
            }
        ]
    }
    return config;

}
)
// 响应拦截器
server.interceptors.response.use(response => {
    // 清除当前记录
    // for (let p in pending) {
    //     if (pending[p].u === (response as any).config.url.split('?')[0] + '&' + response.config.method) {
    //         pending.splice(p as unknown as number, 1);
    //     }
    // }
    return new Promise((resolve, reject) => {
        const { data, status } = response;
        if (status !== 200) {
            let msg = showStatus(status);
            message.error(msg, 2, () => Modal.destroyAll());
            reject(data);
        } else {
            // 1520 ==>续签
            if (data.code === 200 ||
                data.code === 600 || // 合同主体
                data.code === 1401 ||
                data.code === 1520 || // 续单
                data.code === 3520 || // 续单
                data.code === 1805 ||
                data.code === 501 || // 强制开启合同
                data.code === 1522 ||// 工价异动
                data.code === 1523 // 续单设置提成
            ) {
                resolve(data)
            } else {
                message.error(data.msg);
                reject(data)
                if (data.code === 401) {
                    let timer = setTimeout(() => {
                        sessionStorage.clear()
                        window.location.href = window.location.origin;
                        clearTimeout(timer)
                    }, 2000)
                }
            }
        }
    })
}, error => {
    // pending = [];//清空记录
    // return Promise.reject(error)
})

const showStatus = (status: number) => {
    let message = ''
    switch (status) {
        case 400:
            message = '请求错误(400)'
            break
        case 401:
            message = '未授权，请重新登录(401)'
            break
        case 403:
            message = '拒绝访问(403)'
            break
        case 404:
            message = '请求出错(404)'
            break
        case 408:
            message = '请求超时(408)'
            break
        case 500:
            message = '服务器错误(500)'
            break
        case 501:
            message = '服务未实现(501)'
            break
        case 502:
            message = '网络错误(502)'
            break
        case 503:
            message = '服务不可用(503)'
            break
        case 504:
            message = '网络超时(504)'
            break
        case 505:
            message = 'HTTP版本不受支持(505)'
            break
        default:
            message = `连接出错(${status})!`
    }
    return `${message}，请检查网络或联系管理员！`
}

export const uploadExcel = (url: string, param: any, method?: Method) => {
    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
            'timestamp': getCurrentTimeStamp(),
            'appKey': appKey,
            'version': version,
            'sign': getSign(JSON.stringify(param)),
            'extra': '',
            'Authorization': `Bearer ${cookie.get("Authorization")}`
        }
    };
    axios.post(baseURL + url, param, config);
}
export default server;



