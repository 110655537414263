
import request from "server";

/**
 * 获取部门列表
 */
const getDepartmentTreeselect = (data: object = {}) => {
    return request({
        url: `/system/dept/treeselect`,
        method: 'get',
        data
    })
}
/**
 * 角色用户列表
 */
const getRoleUserList = (data: { deptId?: string, roleId?: string }) => {
    return request({
        url: `/system/user/getUserRoleList`,
        method: 'post',
        data
    })
}
/**
 * 获取所有角色分类数据
 */
const getAllRole = (data: object = {}) => {
    return request({
        url: `/system/sysRoleType/queryAll`,
        method: 'get',
        data
    })
}
/**
 * 
 * @param data 获取角色编辑详情
 * @returns 
 */
const getEditRoleDetail = (roleId: string) => {
    return request({
        url: `/system/role/selectAllCopyRoleByRoleId/${roleId}`,
        method: 'get',
        data: {}
    })
}
/**
 * 获取人员数据
 */
const getAllStaff = (data: object = {}) => {
    return request({
        url: `/system/user/getDeptUsers`,
        method: 'get',
        data
    })
}
/**
 * 添加人员
 * @param data 
 * @returns 
 */
const addMulStaff = (data: { userIds: string[], roleIds: string[] }) => {
    return request({
        url: `/system/role/authUser/selectAll/batchAdd`,
        method: 'put',
        data
    })
}
/**
 * 删除人员角色/user/delUserRole
 * @param data 
 * @returns 
 */
const apiUserDelUserRole = (data: { userIds: any[] }) => {
    return request({
        url: `/system/role/delUserRole`,
        method: 'delete',
        data
    })
}
/**
 * 编辑人员角色 /user/editUserRole
 * @param data 
 * @returns 
 */
const apiUserEditUserRole = (data: { roleIds: any[], userId: string }) => {
    return request({
        url: `/system/user/editUserRole`,
        method: 'put',
        data
    })
}
/**
 * 创建角色类型
 */
const createRoleType = (data: object = {}) => {
    return request({
        url: `/system/sysRoleType`,
        method: 'post',
        data
    })
}
/**
 * 修改角色类型
 */
const editRoleType = (data: object = {}) => {
    return request({
        url: `/system/sysRoleType`,
        method: 'put',
        data
    })
}
/**
 * 删除角色类型
 */
const delRoleType = (sysRoleTypeId: string) => {
    return request({
        url: `/system/sysRoleType/${sysRoleTypeId}`,
        method: 'delete',
        data: {}
    })
}
/**
 * 所属区域配置列表
 */
const getCustomerAreaSetList = (data: object = {}) => {
    return request({
        url: `/customer/department/findClientAreaPage`,
        method: 'post',
        data
    })
}
/**
 * 获取所有客户
 */
export const getAllCustomerList = (data: object = {}) => {
    return request({
        url: `/customer/customerInfo/findAll`,
        method: 'get',
        data
    })
}
/**
 * 获取客户下面的部门
 */
const getAlltDepartmentList = (customerId: string) => {
    return request({
        url: `/customer/department/findAll/${customerId}`,
        method: 'get',
        data: {}
    })
}
/**
 * 新增 修改删除 客户区域
 */
const editOrDeleteCustomerArea = (data: object = {}) => {
    return request({
        url: `/customer/department/updateClientArea`,
        method: 'post',
        data
    })
}

/**
 * 借支配置
 */
const getLimitList = (data: object = {}) => {
    return request({
        url: `/finance/borrowSetting/pages`,
        method: 'post',
        data
    })
}
/**
 * 保存借支设置/borrowSetting/save
 */
const saveBorrowSettingSet = (data: object = {}) => {
    return request({
        url: `/finance/borrowSetting/save`,
        method: 'post',
        data
    })
}
/**
 * 获取借支详情
 */
const getBorrowSettingDetail = (departmentId: string) => {
    return request({
        url: `/finance/borrowSetting/detail/${departmentId}`,
        method: 'get',
        data: {}
    })
}

// 借支锁定部门
const getBorrowSettingLockedByUser = () => {
    return request({
        url: `/finance/borrowSetting/getDepartmentLockedByUser`,
        method: 'post',
    })
}

// 锁定部门保存
const borrowSettingLockedSave = (data: any) => {
    return request({
        url: `/finance/borrowSetting/saveDepartmentLockedByUser`,
        method: 'post',
        data
    })
}

//当前企业部门下所有在职人员
const queryBorrowSettingMember = (data: any) => {
    return request({
        url: `/recruitment/appstaffmanagement/onjob/queryBorrowSettingMember`,
        method: 'post',
        data
    })
}
// 
/**
 * 限制预支名单-列表
 */
const getBorrowLimitMember = (data: object = {}) => {
    return request({
        url: `/finance/borrowLimitMember/getPage`,
        method: 'post',
        data
    })
}
/**
 * 限制预支名单-新增
 */
const addBorrowLimitMember = (data: object = {}) => {
    return request({
        url: `/finance/borrowLimitMember/save`,
        method: 'post',
        data
    })
}
/**
 * 限制预支名单-移除
 */
const delBorrowLimitMember = (data: object = {}) => {
    return request({
        url: `/finance/borrowLimitMember/delete`,
        method: 'post',
        data
    })
}
/**
 * 门区区域列表
 */
const apiGetShopAreaList = (data: object = {}) => {
    return request({
        url: `/system/shopArea/treeselect`,
        method: 'get',
        data
    })
}
/**
 * 新增下级
 */
const apiShopAreaAdd = (data: object = {}) => {
    return request({
        url: `/system/shopArea`,
        method: 'post',
        data
    })
}
/**
 * 删除区域
 */
const apiDelShopArea = (shopAreaId: string) => {
    return request({
        url: `/system/shopArea/${shopAreaId}`,
        method: 'delete',
        data: {}
    })
}

/**
 * 数据权限管理列表
 */
const apiAuthoritySharingList = (data: object = {}) => {
    return request({
        url: `/system/authoritySharing/findPage`,
        method: 'post',
        data
    })
}
/**
 * 根据数据来源查询共享菜单数据
 */
const apiAuthoritySharingFindMenuBySourceId = (data: object = {}) => {
    return request({
        url: `/system/authoritySharing/findMenuBySourceId`,
        method: 'post',
        data
    })
}
/**
 * 修改共享权限状态 /authoritySharing/updateStatus
 */
const apiAuthoritySharingupdateStatus = (data: object = {}) => {
    return request({
        url: `/system/authoritySharing/updateStatus`,
        method: 'post',
        data
    })
}
/**
 * 删除数据共享权限 
 */
const apiAuthoritySharingDel = (data: object = {}) => {
    return request({
        url: `/system/authoritySharing/del`,
        method: 'post',
        data
    })
}
/**
 * 保存数据共享权限  /authoritySharing/save
 */
const apiAuthoritySharingSave = (data: object = {}) => {
    return request({
        url: `/system/authoritySharing/save`,
        method: 'post',
        data
    })
}
const getDeptUsersByDeptId = (data: string | number) => {
    return request({
        url: `/system/user/getDeptUsersByDeptId/${data}`,
        method: 'get',
        data: {}
    })
}
/**
 * 智能机器人设置
 */
// 机器人详情
const robotInfo = () => {
    return request({
        url: `/kefu-im/robot`,
        method: 'get',
    })
}
// 机器人保存
const saveRobot = (data: object = {}) => {
    return request({
        url: `/kefu-im/robot`,
        method: 'post',
        data
    })
}
// 服务组列表
const getServiceGroupList = (data: object = {}) => {
    return request({
        url: `/kefu-im/serviceGroup/getList`,
        method: 'post',
        data
    })
}

// 新增服务组
const addServiceGroup = (data: object = {}) => {
    return request({
        url: `/kefu-im/serviceGroup`,
        method: 'post',
        data
    })
}
// 编辑服务组
const editServiceGroup = (data: object = {}, id: any) => {
    return request({
        url: `/kefu-im/serviceGroup/${id}`,
        method: 'put',
        data
    })
}
// 删除服务组
const delServiceGroup = (id: any) => {
    return request({
        url: `/kefu-im/serviceGroup/${id}`,
        method: 'delete',
    })
}
// 获取角色类别
const sysRoleTypeAll = () => {
    return request({
        url: `/system/sysRoleType/queryAllAndAll`,
        method: 'get',
    })
}
// 根据角色获取人员
const queryUserByRoleTypeId = (data: object = {}) => {
    return request({
        url: `/system/role/queryUserByRoleTypeId`,
        method: 'post',
        data
    })
}
// 根据客服id集合，获取客服的状态
const getUserStatusByUserIds = (data: any) => {
    return request({
        url: `/kefu-im/serviceGroup/getUserStatusByUserIds`,
        method: 'post',
        data
    })
}
// 服务组下拉
const getServiceGroupSelect = () => {
    return request({
        url: `/kefu-im/serviceGroup/getList2`,
        method: 'post',
    })
}
// 人工服务分类列表
const getServiceList = (data: object = {}) => {
    return request({
        url: `/kefu-im/serviceCategory/getList2`,
        method: 'post',
        data
    })
}
// 分类
const getServiceCategoryTree = () => {
    return request({
        url: `/kefu-im/serviceCategory/getListForSelect`,
        method: 'get',
    })
}

// 详情
const serviceCategoryDetail = (categoryId: string) => {
    return request({
        url: `/kefu-im/serviceCategory/${categoryId}`,
        method: 'get',
    })
}

// 添加服务类
const addServiceCategory = (data: any) => {
    return request({
        url: `/kefu-im/serviceCategory`,
        method: 'post',
        data
    })
}

// 修改服务类
const updateServiceCategory = (data: any, serviceCategoryId: string) => {
    return request({
        url: `/kefu-im/serviceCategory/${serviceCategoryId}`,
        method: 'put',
        data,
    })
}
// 删除服务类
const deleteServiceCategory = (serviceCategoryId: string) => {
    return request({
        url: `/kefu-im/serviceCategory/${serviceCategoryId}`,
        method: 'delete',
    })
}

/**
 * 客户区域配置
 */

// 客户区域配置列表
export const getAreaConfigList = () => {
    return request({
        url: `/recruitment/serviceAreaConfigController/getAreaConfigList`,
        method: 'post',
    })
}
// 新增客户区域
export const saveOrUpdateAreaConfig = (data: any) => {
    return request({
        url: `/recruitment/serviceAreaConfigController/saveOrUpdateAreaConfig`,
        method: 'post',
        data
    })
}

// 删除客户区域
export const deleteServiceAreaConfigByAreaId = (areaId: any) => {
    return request({
        url: `/recruitment/serviceAreaConfigController/deleteServiceAreaConfigByAreaId/${areaId}`,
        method: 'post',
    })
}

// 所有部门列表
export const getCustomerDepartmentList = (data: any) => {
    return request({
        url: `/recruitment/serviceAreaConfigController/getCustomerDepartmentList`,
        method: 'post',
        data
    })
}
// 保存关联客户部门
export const saveServiceAreaCustomerList = (data: any) => {
    return request({
        url: `/recruitment/serviceAreaConfigController/saveServiceAreaCustomerList`,
        method: 'post',
        data
    })
}
// 区域关联部门
export const getServiceAreaCustomerList = (data: any) => {
    return request({
        url: `/recruitment/serviceAreaConfigController/getServiceAreaCustomerList`,
        method: 'post',
        data
    })
}

// 删除部门
export const deleteServiceAreaCustomerById = (id: any) => {
    return request({
        url: `/recruitment/serviceAreaConfigController/deleteServiceAreaCustomerById/${id}`,
        method: 'post',
    })
}


// 人员列表
export const getServiceAreaAuditorList = (data: any) => {
    return request({
        url: `/recruitment/serviceAreaConfigController/getServiceAreaAuditorList`,
        method: 'post',
        data
    })
}

// 保存人员
export const saveServiceAreaAuditorList = (data: any) => {
    return request({
        url: `/recruitment/serviceAreaConfigController/saveServiceAreaAuditorList`,
        method: 'post',
        data
    })
}

// 删除人员
export const deleteServiceAreaAuditorById = (id: any) => {
    return request({
        url: `/recruitment/serviceAreaConfigController/deleteServiceAreaAuditorById/${id}`,
        method: 'post',
    })
}

const apiNewJurisdiction = {
    getDepartmentTreeselect,
    getRoleUserList,
    getAllRole,
    getAllStaff,
    addMulStaff,
    createRoleType,
    editRoleType,
    delRoleType,
    getCustomerAreaSetList,
    getAllCustomerList,
    getAlltDepartmentList,
    editOrDeleteCustomerArea,
    getLimitList,
    saveBorrowSettingSet,
    getBorrowSettingDetail,
    apiGetShopAreaList,
    apiShopAreaAdd,
    apiDelShopArea,
    apiAuthoritySharingList,
    apiAuthoritySharingFindMenuBySourceId,
    apiAuthoritySharingupdateStatus,
    apiAuthoritySharingDel,
    apiAuthoritySharingSave,
    apiUserDelUserRole,
    apiUserEditUserRole,
    getDeptUsersByDeptId,
    getEditRoleDetail,
    robotInfo,
    saveRobot,
    getServiceGroupList,
    getServiceGroupSelect,
    getServiceList,
    getServiceCategoryTree,
    serviceCategoryDetail,
    addServiceCategory,
    updateServiceCategory,
    deleteServiceCategory,
    addServiceGroup,
    editServiceGroup,
    delServiceGroup,
    sysRoleTypeAll,
    queryUserByRoleTypeId,
    getUserStatusByUserIds,
    getBorrowSettingLockedByUser,
    borrowSettingLockedSave,
    getBorrowLimitMember,
    addBorrowLimitMember,
    delBorrowLimitMember,
    queryBorrowSettingMember
}
export default apiNewJurisdiction
