import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
interface InitModel {
    kitVisible: boolean;
    callUserID: string;
    groupID: string;
    roomID: any;
}
const initTim: InitModel = {
    kitVisible: false, // 是否展示语音框
    callUserID: "", // 通话的用户id
    groupID: "", // 群主键groupID
    roomID: "", // 房间id
}

const timSlice = createSlice({
    name: 'tim',
    initialState: initTim,
    reducers: {
        // 是否展示语音框
        updateKitVisible: (state, action: PayloadAction<boolean>) => {
            state.kitVisible = action.payload;
        },
        // 通话的用户id
        updateCallUserID: (state, action: PayloadAction<string>) => {
            state.callUserID = action.payload;
        },
        // 群主键groupID
        updateGroupID: (state, action: PayloadAction<string>) => {
            state.groupID = action.payload;
        },
        // 群主键roomID
        updateRoomID: (state, action: PayloadAction<string>) => {
            state.roomID = action.payload;
        },
        // 重置
        resetTim: (state) => {
            state.kitVisible = false;
            state.callUserID = '';
        },
    },
    extraReducers: (builder) => {

    },
})

export const {
    updateKitVisible,
    updateCallUserID,
    updateGroupID,
    updateRoomID,
    resetTim
} = timSlice.actions
export default timSlice.reducer;