import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Idict {
    dictId: number | undefined;
    customShow: boolean;
}

const initialState: Idict = {
    dictId: undefined,
    customShow: false
}

export const dictionarySlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setDictId: (state, action: PayloadAction<number>) => {
            state.dictId = action.payload
        },
        setCustomShow: (state, action: PayloadAction<boolean>) => {
            state.customShow = action.payload
        },
    },
})

export const { setDictId, setCustomShow } = dictionarySlice.actions

export default dictionarySlice.reducer