import api from "api";
import request from "server";
import moment from "moment";
import { dormitoryAreaSelect, dormitoryAreaSelectFilter, dormitoryBuildSelect, dormitoryRoomSelect, dormitoryBedSelect, managerByRoleList, managerList } from 'api/dormitoryServiceManagement';
import { getChannel, customLabel, storeManager, userStoreList, byRoleDeptIdRecruitmenter, userSpecialStoreList, currentStoreManager, getNewsColumnList } from 'api/recruitment';
import { findChannelTree, findImportChannelTree, findCurrentStoreChannelTree, findChannelTreeNoDelete } from 'api/operationsManagement';
import { attendanceGroup, getDepts, findDepartmentAll } from 'api/customerService';

// 字典
export const getDicData = (id: string) => {
    return new Promise(resolve => {
        api.apiGetDicList(id).then((res: any) => {
            if (res.data) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/dictLabel/g, 'label').replace(/dictCode/g, 'value'))
                resolve(result)
            }
        })
    })
}
// 通过code获取字典数据
export const getDicDataByCode = (data: any) => {
    return new Promise((resolve, reject) => {
        api.apiGetDicListByCode(data).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/dictLabel/g, 'label').replace(/dictCode/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

// 对接渠道
export const getChannelList = (type?: number) => {
    return new Promise((resolve, reject) => {
        getChannel(type).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/dockingChannelName/g, 'label').replace(/dockingChannelId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

// 渠道树
export const getChannelTree = (level: number = 3) => {
    return new Promise((resolve, reject) => {
        findChannelTree(level).then((res: any) => {
            const { data } = res;
            let result = JSON.parse(JSON.stringify(data).replace(/channelName/g, 'label').replace(/channelId/g, 'value'))
            resolve(result)
        }).catch(() => {
            reject();
        })
    })
}


// 渠道树 去除已删除
export const getChannelTreeNoDelete = () => {
    return new Promise((resolve, reject) => {
        findChannelTreeNoDelete().then((res: any) => {
            const { data } = res;
            let result = JSON.parse(JSON.stringify(data).replace(/channelName/g, 'label').replace(/channelId/g, 'value'))
            resolve(result)
        }).catch(() => {
            reject();
        })
    })
}
// 导入客资
export const getImportChannelTree = () => {
    return new Promise((resolve, reject) => {
        findImportChannelTree().then((res: any) => {
            const { data } = res;
            let result = JSON.parse(JSON.stringify(data).replace(/channelName/g, 'label').replace(/channelId/g, 'value'))
            resolve(result)
        }).catch(() => {
            reject();
        })
    })
}

// 当前门店渠道树
export const getCurrentStoreChannelTree = () => {
    return new Promise((resolve, reject) => {
        findCurrentStoreChannelTree().then((res: any) => {
            const { data } = res;
            let result = JSON.parse(JSON.stringify(data).replace(/channelName/g, 'label').replace(/channelId/g, 'value'))
            resolve(result)
        }).catch(() => {
            reject();
        })
    })
}

// 获取门市数据
export const getStoreList = (data: any) => {
    return new Promise((resolve, reject) => {
        storeManager(data).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data.rows).replace(/shopName/g, 'label').replace(/shopId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}
// 根据登录角色获取门市
export const getCurrentStoreList = (data: any) => {
    return new Promise((resolve, reject) => {
        currentStoreManager(data).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/shopName/g, 'label').replace(/shopId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}


// 获取客资配置管理员
export const getRecruitmenter = (deptId: string) => {
    return new Promise((resolve, reject) => {
        byRoleDeptIdRecruitmenter(deptId).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/nickName/g, 'label').replace(/userId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

// 登录用户可查看的门店列表
export const getUserStoreList = () => {
    return new Promise((resolve, reject) => {
        userStoreList().then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/shopName/g, 'label').replace(/shopId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}
// 获取门店
export const getUserSpecialStoreList = () => {
    return new Promise((resolve, reject) => {
        userSpecialStoreList().then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/shopName/g, 'label').replace(/shopId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}
// 获取供应商数据
export const getCarSupplierList = () => {
    return request({
        url: '/transport/carSupplier/allList',
        method: 'get',
        data: {}
    })
}


// 订单自定义标签
export const getCustomLabelList = () => {
    return new Promise((resolve, reject) => {
        customLabel().then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/orderUserLabelId/g, 'value'));
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}
// 图片反显处理
export const dealShowFile = (imgs: Array<any>) => {
    let arr = [] as any;
    if (imgs.length) {
        imgs.forEach((el: any) => {
            arr.push({ url: el, name: el });
        })
    }
    return arr
}

// 树形部门人员组织架构禁选部门
export const disabledDept = (arr: any[]) => {
    if (!arr.length) return [];
    const depts = (arr: Array<any>) => {  //部门不能选择，只能选择人员
        arr.forEach((el: any) => {
            if (el.type !== 2) {
                el.disabled = true;
            }
            if (el?.children && el?.children.length) {
                depts(el.children)
            }
        })
    }
    depts(arr);
    return arr;
}

// 树形部门人员组织架构
export const getUserInfo = (arr: any[], key: string, id: string) => {  //key查找哪个字段的值
    let userInfo: any
    const getInfo = (arr: Array<any>) => {
        arr.forEach((el: any) => {
            if (el[key] === id) {
                userInfo = el;
                return;
            }
            if (el?.children && el?.children.length) {
                getInfo(el.children)
            }
        })
    }
    getInfo(arr);
    return userInfo;
}
// 图片保存处理
export const dealSaveUpload = (fieldList: Array<any>) => {
    let arr = [] as any;
    fieldList.forEach((el: any) => {
        arr.push(el.photoUrl);
    })
    return arr;
}

export const getAreaSelect = (params = {}) => {
    return new Promise((resolve, reject) => {
        dormitoryAreaSelect({ ...params }).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/name/g, 'label').replace(/regionId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

export const getAreaSelectFilter = () => {
    return new Promise((resolve, reject) => {
        dormitoryAreaSelectFilter().then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/name/g, 'label').replace(/regionId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

export const getBuildSelect = (id: string, params = {}) => {
    return new Promise((resolve, reject) => {
        dormitoryBuildSelect(id, params).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/name/g, 'label').replace(/buildingId/g, 'value'));
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

export const getRoomSelect = (params = {}) => {
    return new Promise((resolve, reject) => {
        dormitoryRoomSelect(params).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/roomNo/g, 'label').replace(/roomId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

export const getBedSelect = (id: string, filter?: number) => {
    return new Promise((resolve, reject) => {
        dormitoryBedSelect(id, filter).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/bedNo/g, 'label').replace(/bedId/g, 'value'));
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

export const getManagerByRoleList = (roleKey: string) => {
    return new Promise((resolve, reject) => {
        managerByRoleList(roleKey).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/nickName/g, 'label').replace(/userId/g, 'value'));
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

export const getManagerList = (data: any) => {
    return new Promise((resolve, reject) => {
        managerList(data).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/nickName/g, 'label').replace(/userId/g, 'value'));
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

// 栏目列表
export const getColumnDataList = (data: any) => {
    return new Promise((resolve, reject) => {
        getNewsColumnList(data).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data).replace(/name/g, 'label').replace(/columnId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

// 考勤组列表
export const getAttendanceGroupList = (data: any) => {
    return new Promise((resolve, reject) => {
        attendanceGroup(data).then((res: any) => {
            if (res && res.code === 200) {
                let result = JSON.parse(JSON.stringify(res.data.rows).replace(/attendanceName/g, 'label').replace(/attendanceId/g, 'value'))
                resolve(result)
            }
        }).catch(() => {
            reject();
        })
    })
}

export const getDeptsData = () => {
    return new Promise((resolve) => {
        getDepts().then(res => {
            if (res && res.code === 200) {
                let data = JSON.parse(JSON.stringify(res.data).replace(/departmentId/g, 'value'))
                data.forEach((item: any) => {
                    if (item.shortName != item.name) {
                        item.label = item.shortName + "-" + item.name
                    } else {
                        item.label = item.name;
                    }
                });
                resolve(data);
            } else {

            }
        });
    })
}
// 所有企业部门
export const getDeptsAll = () => {
    return new Promise((resolve) => {
        findDepartmentAll().then(res => {
            if (res && res.code === 200) {
                let data = JSON.parse(JSON.stringify(res.data).replace(/departmentId/g, 'value').replace(/name/g, 'label'))
                resolve(data);
            } else {

            }
        });
    })
}

export const getLastDay = (i: number, dateStr: string) => {
    let day = moment(new Date(dateStr)).subtract(i, 'days').format('YYYY-MM-DD');
    return day;
}

// 获取本星期
export const getCurrentWeek = (dateStr: string) => {
    const start = moment(new Date(dateStr)).startOf('week').format('YYYY-MM-DD'); //本周一
    const end = moment(new Date(dateStr)).endOf('week').format('YYYY-MM-DD'); //本周日
    return [start, end]
}

/** * 获取前 i 周的周一和周日日期，并以数组的方式返回。 * 当 i=1，获取的是上周一和上周日的日期； * 当 i=2，获取的是上上周一和上上周日的日期 * ...以此类推 * @param i */
export const getLastWeek = (i: number, dateStr: string) => {
    let weekOfDay = parseInt(moment(new Date(dateStr)).format('E'));//计算今天是这周第几天
    let last_monday = moment(new Date(dateStr)).subtract(weekOfDay + 7 * i - 1, 'days').format('YYYY-MM-DD');//周一日期
    let last_sunday = moment(new Date(dateStr)).subtract(weekOfDay + 7 * (i - 1), 'days').format('YYYY-MM-DD');//周日日期
    return [last_monday, last_sunday]
}

// 获取月份
export const getLastMonth = (i: number, dateStr: string) => {
    let month = moment(new Date(dateStr)).subtract(i, 'months').format('YYYY-M');
    return month;
}