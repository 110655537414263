import { Method } from "axios";
import request from "server";
/**
 * 角色列表
 */
const apiGetRoleTypeList = (data: object = {}) => {
    return request({
        url: `/system/role/list`,
        method: 'get',
        data
    })
}
// 删除角色
const apiDeleteRole = (roleIds: string, roleTypeId?: string) => {
    return request({
        url: `/system/role/${roleTypeId}/${roleIds}`,
        method: 'delete',
    })
}
const apiGetRolepersonList = (data: { roleId: number }) => {
    return request({
        url: `/system/role/authUser/selectUserByRole/${data.roleId}`,
        method: 'get',
        data
    })
}
const apiGetRoleSelectList = (data: { userId: number }) => {
    return request({
        url: `/system/role/optionselect/${data.userId}`,
        method: 'get',
        data
    })
}
const apiAddOrEditDicRole = (data: object = {}, method?: Method) => {
    return request({
        url: `/system/role`,
        method: method || 'post',
        data
    })
}
const apiAddPerson = (data: object = {}) => {
    return request({
        url: `/system/user/authRoleSingle`,
        method: 'post',
        data
    })
}
//取消用户角色
const apiCancleRolePerson = (data: Object = {}) => {
    return request({
        url: `/system/role/authUser/cancel`,
        method: 'put',
        data
    })
}
const apiUserList = (data: Object = {}, id: string | Number = '') => {
    console.log(data)
    return request({
        url: `/system/user/list?deptId=${id}`,
        method: 'get',
        data
    })
}

//根据角色获取菜单
const apiGetRolePermission = (data: { roleId: number | string, sourceType: string }) => {
    return request({
        url: `/system/menu/roleMenuTreeselect/${data.roleId}/${data.sourceType}`,
        method: 'get',
        data
    })
}
// 保存角色菜单权限
const apiSaveMenus = (data: { roleId: number | string, menuIds: Array<string>, sourceType: string }) => {
    return request({
        url: `/system/role/menuIds`,
        method: 'post',
        data
    })
}
/**
 * 驻厂客服配置
 */

// 客户列表
const customerList = (data: Object) => {
    return request({
        url: `/customer/customerInfo/findAllWithConditions`,
        method: 'post',
        data
    })
}
// 所有客服
const allCustomerServices = (data: any) => {
    return request({
        url: `/system/role/authUser/selectUserByRoleContractor`,
        method: 'post',
        data
    })
}
// 新增客服
const addCustomerService = (data: any) => {
    return request({
        url: `/customer/customerService`,
        method: 'post',
        data
    })
}
// 删除客服
const deleteCustomerService = (data: any) => {
    return request({
        url: `/customer/customerService/${data.customerServiceId}`,
        method: 'delete',
    })
}
// 设置投诉处理人
const addComplaintDeal = (customerServiceId: string, flag: number) => {
    return request({
        url: `/customer/customerService/changeCustomerServiceComplaintFlag/${customerServiceId}/${flag}`,
        method: 'post',
    })
}
// 设置取消对外处理人
const addExternalContact = (customerServiceId: string, flag: number) => {
    return request({
        url: `/customer/customerService/changeCustomerServiceOutContactMan/${customerServiceId}/${flag}`,
        method: 'post',
    })
}
// 设置导入在职人员
const addImportJob = (customerServiceId: string, flag: number) => {
    return request({
        url: `/customer/customerService/importOnjobFlag/${customerServiceId}/${flag}`,
        method: 'post',
    })
}
// 设置取新员工登记手机号
const setNewEmpTel = (customerServiceId: string, flag: number) => {
    return request({
        url: `/customer/customerService/changeNewMemberPhone/${customerServiceId}/${flag}`,
        method: 'post',
    })
}
// 设置取新员工登记手机号
const setNewEmpManagerTel = (customerServiceId: string, flag: number) => {
    return request({
        url: `/customer/customerService/changeMemberPhone/${customerServiceId}/${flag}`,
        method: 'post',
    })
}

/**
 * 驻厂客服-new
 * @param  
 * @returns 
 */
// 列表
export const selectAllCustomerServiceByDepartmentId = (data: any) => {
    return request({
        url: `/customer/customerService/selectAllCustomerServiceByDepartmentId`,
        method: 'post',
        data
    })
}
// 新增/修改
export const saveOrUpdateCustomerServiceSet = (data: any) => {
    return request({
        url: `/customer/customerService/saveOrUpdateCustomerServiceSet`,
        method: 'post',
        data
    })
}
// 删除
export const delCustomer = (customerServiceId: string) => {
    return request({
        url: `/customer/customerService/${customerServiceId}`,
        method: 'delete',
    })
}
// 驻厂客服查询
export const selectCustomerServiceVOById = (data: any) => {
    return request({
        url: `/customer/customerService/selectCustomerServiceVOById`,
        method: 'post',
        data
    })
}

// 银行卡前置录入
export const queryBank = (data: any) => {
    return request({
        url: `/customer/departmentBankConfig/query`,
        method: 'post',
        data
    })
}

// 
export const saveBank = (data: any) => {
    return request({
        url: `/customer/departmentBankConfig/save`,
        method: 'post',
        data
    })
}
// 根据客户/部门查询客服
const queryById = (queryId: string) => {
    return request({
        url: `/customer/customerService/queryAll/${queryId}`,
        method: 'get',
    })
}
const receptionCustomerInfoAll = () => {
    return request({
        url: `/customer/receptionGroupConfInfo/queryAll`,
        method: 'get',
    })
}
const receptionCustomerInfoAllAdd = (data: any) => {
    return request({
        url: `/customer/receptionGroupConfInfo`,
        method: 'POST',
        data
    })
}
const receptionDepartment = (data: any) => {
    return request({
        url: `/customer/receptionCustomerInfo/queryAll/${data}`,
        method: 'get',
    })
}
const receptionDepartmentAdd = (data: any) => {
    return request({
        url: `/customer/receptionCustomerInfo`,
        method: 'post',
        data
    })
}
const receptionDepartmentDel = (receptionCustomerInfoId: any) => {
    return request({
        url: `/customer/receptionCustomerInfo/${receptionCustomerInfoId}`,
        method: 'DELETE',
    })
}
const receptionPersonnel = (receptionCustomerId: any) => {
    return request({
        url: `/customer/receptionistInfo/queryAll/${receptionCustomerId}`,
        method: 'get'
    })
}
const receptionPersonnelDel = (receptionistInfoId: any) => {
    return request({
        url: `/customer/receptionistInfo/${receptionistInfoId}`,
        method: 'DELETE'
    })
}
const receptionPersonnelAdd = (data: any) => {
    return request({
        url: `/customer/receptionistInfo`,
        method: 'post',
        data
    })
}
/**
 * 签约主体管理
 */
const getCompanyListPage = (data: any) => {
    return request({
        url: `/customer/company/companyListPage`,
        method: 'post',
        data
    })
}
const companyAddOrUpdate = (data: any) => {
    return request({
        url: `/customer/company/companyAddOrUpdate`,
        method: 'post',
        data
    })
}
/**
 * 放款主体配置
 */
const getlenderData = (data: any) => {
    return request({
        url: `/operation/company/listPage`,
        method: 'post',
        data
    })
}
// 放款主体
const getAlllenderData = () => {
    return request({
        url: `/operation/company/listCompany`,
        method: 'get',
        data: {}
    })
}

// 业务主体
const getListOrder = (data: any) => {
    return request({
        url: `/operation/recommendMakingReceipt/listOrder`,
        method: 'post',
        data
    })
}

/**
 * 保存放款主体
 */
const saveLender = (data: any) => {
    return request({
        url: `/operation/company/save`,
        method: 'post',
        data
    })
}
/**
 * 删除放款主体
 */
const delLender = (data: any) => {
    return request({
        url: `/operation/company/removeCompany`,
        method: 'post',
        data
    })
}
/**
 * 关于我们
 */

// 查询详情
const aboutUsDetails = () => {
    return request({
        url: `/system/aboutus/getAboutusDetails`,
        method: 'get',
    })
}

// 编辑公司信息
const updateAboutUs = (data: any) => {
    return request({
        url: `/system/aboutus/updateAboutus`,
        method: 'post',
        data
    })
}
/**
 * 智能机器人设置
 */

// 查询机器人详情
const robotDetails = () => {
    return request({
        url: `/kefu-im/robot`,
        method: 'get',
    })
}

// 编辑机器人信息
const updateRobot = (data: any) => {
    return request({
        url: `/kefu-im/robot`,
        method: 'post',
        data
    })
}

// 获取面试资料
const getInterviewPrepare = (data: any) => {
    return request({
        url: `/customer/interviewPrepare/getInterviewPrepare`,
        method: 'post',
        data
    })
}

// 面试资料更新
const updateInterviewPrepare = (data: any) => {
    return request({
        url: `/customer/interviewPrepare/addOrUpdate`,
        method: 'post',
        data
    })
}

// 资料收集列表
const formCollectList = (data: any) => {
    return request({
        url: `/elec-contract/jiepuCollect/formCollectList`,
        method: 'post',
        data
    })
}

/**
 * 原因配置
 * @param data 
 * @returns 
 */

// 配置列表
export const getReasonConfigList = (data: any) => {
    return request({
        url: `/recruitment/reasonConfigController/getReasonConfigList`,
        method: 'post',
        data
    })
}

// 新增原因 
export const saveOrUpdateReasonConfig = (data: any) => {
    return request({
        url: `/recruitment/reasonConfigController/saveOrUpdateReasonConfig`,
        method: 'post',
        data
    })
}

// 删除
export const deleteReasonConfigByReasonId = (id: string) => {
    return request({
        url: `/recruitment/reasonConfigController/deleteReasonConfigByReasonId/${id}`,
        method: 'post',
    })
}

const apiJurisdiction = {
    apiGetRoleTypeList,
    apiDeleteRole,
    apiGetRolepersonList,
    apiAddOrEditDicRole,
    apiGetRoleSelectList,
    apiAddPerson,
    apiCancleRolePerson,
    apiUserList,
    apiGetRolePermission,
    apiSaveMenus,
    customerList,
    addCustomerService,
    deleteCustomerService,
    addComplaintDeal,
    addExternalContact,
    addImportJob,
    queryById,
    allCustomerServices,
    receptionCustomerInfoAll,
    receptionDepartment,
    receptionPersonnel,
    receptionCustomerInfoAllAdd,
    receptionDepartmentAdd,
    receptionDepartmentDel,
    receptionPersonnelDel,
    receptionPersonnelAdd,
    getCompanyListPage,
    companyAddOrUpdate,
    aboutUsDetails,
    updateAboutUs,
    robotDetails,
    updateRobot,
    setNewEmpTel,
    setNewEmpManagerTel,
    getlenderData,
    saveLender,
    delLender,
    getAlllenderData,
    getListOrder,
    getInterviewPrepare,
    updateInterviewPrepare,
    formCollectList
}
export default apiJurisdiction